class BaseViewStats {
  constructor(data) {
    this.data = data.content.records
    this.domains = data.content.domains
    this.domain_id = data.content.domain_id
    this.reputations = {
      "HIGH": 1,
      "MEDIUM": 0.5,
      "LOW": 0.25,
      "BAD": 0,
      "REPUTATION_CATEGORY_UNSPECIFIED": 0,
    }

    this.ip_reputations_names = ['HIGH', 'MEDIUM', 'LOW', 'BAD']
    this.ip_reputations_values = {
      'HIGH': 100,
      'MEDIUM': 75,
      'LOW': 50,
      'BAD': 0
    }
    this.ip_reputations = data.content.ip_reputations
    this.show_ip_rep = data.content.show_ip_rep
  }

  formatData() {
    const userReportedSpamRatioData = []
    const feedbackLoopData = []
    const deliveryErrorsData = []
    const domainReputationData = []
    const authenticationData = []
    const encryptionData = []
    this.domains.forEach(domain => {
      const spamRatio = {
        name: `${domain.name}`,
        data: {}
      }

      const feedbackLoop = {
        name: `${domain.name}`,
        data: {}
      }

      const deliveryErrors = {
        name: `${domain.name}`,
        data: {}
      }

      const domainReputation = {
        name: `${domain.name}`,
        data: {}
      }

      const dmarcSuccessRatio = {
        name: `DMARC - ${domain.name}`,
        data: {}
      }

      const dkimSuccessRatio = {
        name: `DKIM - ${domain.name}`,
        data: {}
      }

      const spfSuccessRatio = {
        name: `SPF - ${domain.name}`,
        data: {}
      }

      const inboundEncryption = {
        name: `Inbound - ${domain.name}`,
        data: {}
      }

      const outboundEncryption = {
        name: `Outbound - ${domain.name}`,
        data: {}
      }

      const records = this.selectRecordsForDomain(domain.id)
      records.forEach(el => {
        spamRatio.data[el.date] = el.user_reported_spam_ratio * 100
        feedbackLoop.data[el.date] = el.spammy_feedback_loops * 100
        deliveryErrors.data[el.date] = el.delivery_errors * 100
        domainReputation.data[el.date] = this.reputations[el.domain_reputation] * 100
        dmarcSuccessRatio.data[el.date] = el.dmarc_success_ratio * 100
        dkimSuccessRatio.data[el.date] = el.dkim_success_ratio * 100
        spfSuccessRatio.data[el.date] = el.spf_success_ratio * 100
        inboundEncryption.data[el.date] = el.inbound_encryption_ratio * 100
        outboundEncryption.data[el.date] = el.outbound_encryption_ratio * 100
      })
      userReportedSpamRatioData.push(spamRatio)
      feedbackLoopData.push(feedbackLoop)
      deliveryErrorsData.push(deliveryErrors)
      domainReputationData.push(domainReputation)
      authenticationData.push(dmarcSuccessRatio, dkimSuccessRatio, spfSuccessRatio)
      encryptionData.push(inboundEncryption, outboundEncryption)
    })

    return {
      userReportedSpamRatioData,
      feedbackLoopData,
      deliveryErrorsData,
      domainReputationData,
      authenticationData,
      encryptionData
    }
  }

  filterIpReputationsData(data) {
    return data.map(rep_data => {
      return {
        name: rep_data.name,
        data: rep_data.data.filter(elem => {
          return (elem[1] !== null && elem[1] !== 'NaN')
        })
      }
    })
  }

  getDataLength(data) {
    let dataLength = []

    this.ip_reputations_names.forEach((_name, idx) => {
      dataLength.push(data[idx].data.length)
    })
    return Math.max(...dataLength)
  }

  formatIpReputationsData(data, label, filter) {
    const dataLength = this.getDataLength(data)
    let newData = {
      name: label,
      data: []
    }
    for (let i = 0; i < dataLength; i++) {
      let average = []
      let newELem = []
      this.ip_reputations_names.forEach((name, idx) => {
        const elem = data[idx].data[i]
        if (elem === undefined) return

        newELem[0] = elem[0]

        if (elem[1] === 0 || elem[1] === null) return

        average.push(this.ip_reputations_values[name])
      })
      newELem = [
        ...newELem,
        (average.reduce((a, b) => (a + b), 0) / average.length).toFixed(2)
      ]
      newData.data.push(newELem)
    }

    if (filter) newData = this.filterIpReputationsData([newData])
    return newData
  }

  selectRecordsForDomain(domain_id) {
    return this.data.filter(record => record.google_postmaster_domain_id === domain_id)
  }
}

export default BaseViewStats
