import React from 'react'
import {useSelector} from 'react-redux'

const InboxPreviewTab = () => {
  const {sender_name, follow_up_text, subject, preheader} = useSelector(state => state)
  return (
    <div className="tab-pane fade" id="inbox_preview">
      <div className="row">
        <div className="small-12 columns">
          <div className="gmailTable">
            <div className="gmailRow">
              <div className="checkbox">
                <div/>
              </div>
              <div className="star">
                <img src="https://ssl.gstatic.com/ui/v1/star/star4.png" alt='star4'/>
              </div>
              <div className="senderName">
                {sender_name}
              </div>
              <div className="subjectLineAndPreHeader">
                <div>
                    <span className="SL">
                      {subject}
                    </span>
                  <span className="pre">
                    - {[preheader, follow_up_text].join(' ')}
                  </span>
                </div>
              </div>
              <div className="time">
                <div>10:00 am</div>
              </div>
              <div className="clear"/>
            </div>
          </div>
        </div>
      </div>
      <div className="row space">
        <div className="small-12 columns"><input id="checkbox" name="checkbox" type="checkbox"/>
          <label className="iPhoneCheckbox" data-off="iPhone" data-on="iPad" htmlFor="checkbox"/>
          <div className="iphone ios" style={{marginTop: '20px'}}>
            <div className="speaker"/>
            <div className="screen">
              <div className="navigationController">
                <div className="back">
                  <div>‹</div>
                  Back
                </div>
                <div className="title">Inbox</div>
                <div className="edit">Edit</div>
              </div>
              <div className="searchBar">
                <div>Search</div>
              </div>
              <div className="tableRow">
                <div className="newEmailIcon"/>
                <div className="sender">
                  {sender_name}
                </div>
                <div className="timeiPhone">10:00 AM ›</div>
                <div className="titleiPhone">
                  {subject}
                </div>
                <div className="preheader" data-ellipsis="">
                  <span>
                    {[preheader, follow_up_text].join(' ')}
                  </span>
                </div>
              </div>
            </div>
            <button/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InboxPreviewTab
