import moment from 'moment'

export const initializeDatepicker = element => {
  return $(element).datetimepicker({
    disabledTimeIntervals: [true],
    format: 'YYYY-MM-DD HH:mm:ss z',
    minDate: new Date(),
    allowInputToggle: true
  })
}

export const initializeTimepicker = (element, element_time, reinitialize) => {
  let defaultDate = ''
  const utcOffset = $("#user_timezone").val()
  if (element_time === undefined || element_time === null) {
    defaultDate = moment().utcOffset(utcOffset).add(5, 'm')
  } else {
    defaultDate = moment(element_time).utcOffset(utcOffset)
  }

  const pickerElement = $($(element).closest('div.input-group'))
  if (pickerElement.data('DateTimePicker') && reinitialize) {
    pickerElement.data('DateTimePicker').destroy()
    $(element).val('')
  }

  pickerElement.datetimepicker({
    format: 'LT',
    defaultDate: defaultDate,
    allowInputToggle: true
  })
}

export const initializeDateWithoutTimepicker = (element, element_date, reinitialize) => {
  let defaultDate = ''
  if (element_date === undefined || element_date === null) {
    defaultDate = new Date()
  } else {
    defaultDate = new Date(element_date)
  }
  const pickerElement = $($(element).closest('div.input-group'))
  if (pickerElement.data('DateTimePicker') && reinitialize) {
    pickerElement.data('DateTimePicker').destroy()
    $(element).val('')
  }
  return pickerElement.datetimepicker({
    format: 'YYYY-MM-DD',
    defaultDate: defaultDate,
    allowInputToggle: true
  }).on("dp.change", function (e) {
    $('.date-without-time-picker').change()
  })
}

export const mergeDateWithTime = parentSelector => {
  const date = $(parentSelector).find('.date-without-time-picker').val()
  const time = $(parentSelector).find('.timepicker').val()
  return date + ' ' + time
}
