class EmailHtmlBuilderTinymceHelper {
  initialize(modal, html, textareaSelector, onChange, height = 400) {
    const textarea = modal.find(textareaSelector)
    textarea.val(html)

    const params = {
      selector: `textarea${textareaSelector}`,
      branding: false,
      menubar: false,
      visual: true,
      plugins: [
        'advlist lists link image fullpage'
      ],
      height: height,
      autoresize_min_height: 400,
      fullpage_default_doctype: "<!DOCTYPE html>",
      file_picker_types: 'image',
      file_picker_callback: (callback, value, meta) => {
        if (meta.filetype === 'image' && $('#share_partner_email_template_id').length > 0) {
          this.filePickerHtmlEmail(callback, value, meta)
        }
      }
    }

    if (onChange !== null) {
      params.setup = editor => editor.on('change', onChange)
    }
    tinyMCE.init(params)
  }

  filePickerHtmlEmail = (callback, value, meta) => {
    const templateId = $('#share_partner_email_template_id').val()
    const imageUrl = value;

    return $.ajax({
      url: '/email_html_builder/tinify_image',
      method: 'POST',
      data: {
        url: imageUrl,
        email_template_id: templateId
      },
      success: response => callback(response.url),
      error: (xhr, status, error) => alert('Something went wrong')
    })
  }
}

export default EmailHtmlBuilderTinymceHelper
