import {initializeSelect2} from '../../helpers/select2_helper'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#networks-edit, #networks-new').length > 0) {
    initializeSelect2('#network_urls', true)
    initializeSelect2('#network_page_ids', true)

    const allUrls = $('#network_all_urls')

    const toggleUrlSelector = () => {
      if (allUrls.is(':checked')) {
        $('#network_urls').attr('required', null)
        return $('.form-group-urls *').hide(250)
      } else {
        $('#network_urls').attr('required', 'required')
        $('.form-group-urls *').show(250)
      }
    }

    toggleUrlSelector()

    allUrls.on('change', toggleUrlSelector)

    $('.network-facebook-filter-form').keypress(e => {
      if (e.keyCode === 13) return false
    })
  }
})
