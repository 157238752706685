import SelectedPostsHelper from '../../helpers/selected_posts_helper'
import PaginatorHelper from '../../helpers/paginator_helper'
import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import FacebookPostFiltersSerializer from '../../helpers/facebook_post_filters_serializer'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#facebook-posts-index').length > 0) {
    const page = $('#page-hidden-container').data('page')
    const facebookPostsHelper = new FacebookPostsHelper()
    const selectedPostsContainer = $('.container-selected-posts')
    const paginatorHelper = new PaginatorHelper()
    const selectedPostsHelper = new SelectedPostsHelper()
    const clearSelectionBtn = $('.btn-clear-selected-posts')

    $(document).on('focus', '.datepicker', e => {
      $(this).datetimepicker({
        disabledTimeIntervals: [true],
        format: 'YYYY-MM-DD HH:mm:ss z',
        minDate: new Date()
      })
    })

    window.toggleSendButtons = () => {
      const selectedRowInf = $('.selected-rows')
      const sendButton = $('.btn-share-partners-send')
      const wpButton = $('.btn-tag-wordpress')
      const tagButton = $('.btn-tag-post')
      // fbButton = $('.btn-send-to-facebook-draft-model')
      const scheduleButton = $('.btn-schedule-facebook-wall-post')

      selectedRowInf.text(
        selectedPostsContainer.find('.facebook-post-checkbox:checked').length + ' rows selected'
      )

      if (selectedPostsContainer.find('.facebook-post-checkbox:checked').length > 0) {
        sendButton.removeClass('disabled')
        tagButton.removeClass('disabled')
        scheduleButton.removeClass('disabled')
        if (wpButton) {
          wpButton.removeClass('disabled')
        }
        selectedRowInf.removeClass('disabled')
        clearSelectionBtn.removeClass('disabled')
      } else {
        sendButton.addClass('disabled')
        tagButton.addClass('disabled')
        scheduleButton.addClass('disabled')
        if (wpButton) {
          wpButton.addClass('disabled')
        }
        selectedRowInf.addClass('disabled')
        clearSelectionBtn.addClass('disabled')
      }
    }

    toggleSendButtons()

    const toggleFilters = () => {
      const moreFilters = $('.more-filters')
      if (moreFilters.hasClass('hidden')) {
        moreFilters.removeClass('hidden')
        $(this).text('Less')
      } else {
        moreFilters.addClass('hidden')
        $(this).text('More')
      }
    }

    $('body').on('change', '.container-facebook-posts .facebook-post-checkbox', selectedPostsHelper.toggleSelectedPost)

    $('body').on('change', '.facebook-post-checkbox', toggleSendButtons)
    $('#toogle-filters').click(toggleFilters)

    $('body').on('mouseover', "[data-toggle='tooltip']", e => {
      const that = $(this)
      that.tooltip({html: true})
      that.tooltip('show')
    })

    $('.send-all-share-partner-checkbox').click(e => {
      let checkboxes
      const that = $(e.target)
      const checked = that.prop('checked')
      const table = that.closest('table')
      if (checked) {
        checkboxes = table.find('.facebook-post-checkbox:not(:checked)')
      } else {
        checkboxes = table.find('.facebook-post-checkbox')
      }

      checkboxes.prop('checked', true).prop('checked', checked).trigger('change')
    })

    const addWordpressTag = e => {
      const modal = $('#tag-wordpress-modal')
      const tag = modal.find('.tag-selector').val()

      $.ajax({
        url: `/pages/${page.id}/facebook_posts/add_tag_on_wordpress`,
        method: 'POST',
        data: {
          ids: facebookPostsHelper.selectedPostsIds(),
          tag
        },
        dataType: 'script',
        complete() {
          // $(checkbox).prop('checked', false) for checkbox in $('.facebook-post-checkbox')
          toggleSendButtons()
          modal.modal('hide')
        }
      })
    }

    const exportPosts = e => {
      const modal = $('#export-modal')
      const imageFormat = modal.find('.image-selector').val()
      const searchParams = new URI().query()

      const filterSerialer = new FacebookPostFiltersSerializer('.filters')
      return $.ajax({
        url: `/pages/${page.id}/facebook_posts/export_posts?` + searchParams,
        method: 'POST',
        data: {
          image_format: imageFormat,
          ids: facebookPostsHelper.selectedPostsIds(),
          filters: filterSerialer.perform()
        },
        dataType: 'script',
        complete() {
          // $(checkbox).prop('checked', false) for checkbox in $('.facebook-post-checkbox')
          toggleSendButtons()
          modal.modal('hide')
        }
      })
    }

    const wordpressTagThrottled = _.throttle(addWordpressTag, 2500, {'trailing': false})
    const exportThrottled = _.throttle(exportPosts, 2500, {'trailing': false})
    const clearSelectionThrottled = _.throttle(selectedPostsHelper.clearSelection, 2500, {'trailing': false})

    $('.pagination a').on('click', paginatorHelper.changePaginationLink)

    $('#per_page').change(paginatorHelper.changePostPerPage)
    $('#tag-wordpress-modal .modal-footer .btn-send-message').click(wordpressTagThrottled)
    $('#export-modal .modal-footer .btn-send-message').click(exportThrottled)
    clearSelectionBtn.click(clearSelectionThrottled)

    $('tbody .fa.fa-hashtag').click(e => {
      const attr_name = 'data-facebook-post-id'
      $('#tag-post-modal').attr(attr_name, $(this).closest('tr').attr(attr_name))
      $('#tag-post-modal').modal('show')
    })

    $(document).on('focusin', e => {
      if ($(e.target).closest(".mce-window").length) {
        e.stopImmediatePropagation()
      }
    })

    $('.facebook-filter-form').keypress(e => {
      if (e.keyCode === 13) {
        return false
      }
    })
  }
})
