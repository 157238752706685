import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import FacebookPostFiltersSerializer from '../../helpers/facebook_post_filters_serializer'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    const networkId = $('.wrapper-networks').data('networkId')
    const facebookPostsHelper = new FacebookPostsHelper()

    const exportPosts = e => {
      const modal = $('#export-modal')
      const imageFormat = modal.find('.image-selector').val()
      const searchParams = new URI().query()
      const filterSerialer = new FacebookPostFiltersSerializer('.filters')

      $.ajax({
        url: `/networks/${networkId}/facebook_posts/export_posts?` + searchParams,
        method: 'POST',
        data: {
          image_format: imageFormat,
          ids: facebookPostsHelper.selectedPostsIds(),
          filters: filterSerialer.perform()
        },
        dataType: 'script',
        complete() {
          // $(checkbox).prop('checked', false) for checkbox in $('.facebook-post-checkbox')
          return modal.modal('hide')
        }
      })
    }

    const exportThrottled = _.throttle(exportPosts, 2500, {'trailing': false})
    $('#export-modal .modal-footer .btn-send-message').click(exportThrottled)
  }
})
