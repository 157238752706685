module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<h2>\n    <i class=\"")
    ; __append(escapeFn( icon_class ))
    ; __append("\"></i>\n    ")
    ; __append(escapeFn( table_name ))
    ; __append("\n</h2>\n<table class=\"table table-striped table-bordered table-hover\">\n   <thead>\n      <tr>\n         <th></th>\n         <th>Total</th>\n      </tr>\n   </thead>\n   <tbody>\n      <tr>\n         <td>Number:</td>\n         <td class=\"total_number_of_posts\">")
    ; __append(escapeFn( total.number_of_posts ))
    ; __append("</td>\n      </tr>\n      <tr>\n         <td>Avg. Engagement:</td>\n         <td class=\"total_average_engagement_rate\">")
    ; __append(escapeFn( total.average_engagement_rate ))
    ; __append("</td>\n      </tr>\n      <tr>\n         <td>Avg. Reach:</td>\n         <td class=\"total_average_reach\">")
    ; __append(escapeFn( total.average_reach ))
    ; __append("</td>\n      </tr>\n      <tr>\n         <td>Total Reach:</td>\n         <td class=\"total_total_reach\">")
    ; __append(escapeFn( total.total_reach ))
    ; __append("</td>\n      </tr>\n      <tr>\n         <td>Avg. CTR:</td>\n         <td class=\"total_average_ctr\">")
    ; __append(escapeFn( total.average_ctr ))
    ; __append("</td>\n      </tr>\n      <tr>\n         <td>Avg. FB Clicks:</td>\n         <td class=\"total_average_fb_clicks\">")
    ; __append(escapeFn( total.average_fb_clicks ))
    ; __append("</td>\n      </tr>\n      <tr>\n         <td>Total FB Clicks:</td>\n         <td class=\"total_total_fb_clicks\">")
    ; __append(escapeFn( total.total_fb_clicks ))
    ; __append("</td>\n      </tr>\n      <tr>\n         <td>% of page size:</td>\n         <td class=\"total_percentage_of_fan_count\">")
    ; __append(escapeFn( total.percentage_of_fan_count ))
    ; __append("</td>\n      </tr>\n   </tbody>\n</table>\n")
  }
  return __output;

}