import React from 'react'
import {useSelector} from 'react-redux'

const Header = () => {
  const {error} = useSelector(state => state)

  return (
    <>
      <div className="error-block">
        {error && (<div className="alert alert-danger fade in m-b-15">{error}</div>)}
      </div>
      <div className="modal-header">
        <h4 className="modal-title" id="send-share-partners-modal-label">
          Send HTML
          <span className='small-gray-text'>
            NOTE: All changes are saved automatically
          </span>
        </h4>
      </div>
    </>
  )
}

export default Header
