import {SortableContainer} from 'react-sortable-hoc'
import React from 'react'
import FacebookPost from "./FacebookPost"
import LinkInput from './LinkInput'
import HtmlSnippet from './HtmlSnippet'
import {useDispatch} from 'react-redux'

const TableList = SortableContainer(({items}) => {

  const dispatch = useDispatch()

  const removeItem = (index) => {
    items = items.filter((item, idx) => idx !== index)
    items = items.map((item, idx) => {
      return {
        ...item,
        followNumber: idx
      }
    })
    dispatch({type: 'SET_ITEMS', payload: items})
  }

  const renderItem = (type, index, value, name) => {
    switch (type) {
      case 'facebook_post':
        return <FacebookPost key={`${index}-${value}-${type}-${name}`} followNumber={index} removeItem={() => removeItem(index)} index={index} />
      case 'Link':
        return <LinkInput key={`${index}-${value}`} followNumber={index} removeItem={() => removeItem(index)} index={index} />
      case 'html_snippet':
        return <HtmlSnippet key={`${index}-${value}`} followNumber={index} removeItem={() => removeItem(index)} index={index} />
    }
  }
  return (
    <div className="form-group">
      <table className="table table-striped table-hover table-responsive">
        <thead>
        <tr>
          <th/>
          <th>Image</th>
          <th>Title</th>
          <th/>
        </tr>
        </thead>
        <tbody>
          {items.map(({type, value, name}, index) => renderItem(type, index, value, name))}
        </tbody>
      </table>
    </div>
  )
})

export default TableList
