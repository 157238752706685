module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<tr class=\"facebook-post-form\" data-destination-page-id=\"")
    ; __append(escapeFn( pageId))
    ; __append("\" data-slot-id=\"")
    ; __append(escapeFn( slot.id))
    ; __append("\">\n  <td class=\"col-md-7\">")
    ; __append(escapeFn( pageName))
    ; __append("</td>\n  <td class=\"col-md-4\">\n    <div class=\"form-inline\" style=\"position: relative;\">\n      <div class=\"form-group m-r-15\" style=\"width: 135px\">\n        <div class=\"input-group date\">\n          <input type=\"text\" name=\"scheduled_facebook_wall_post[publish_at]\" class=\"form-control  date-without-time-picker\" value=\"")
    ; __append(escapeFn( slot.date))
    ; __append("\">\n          <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n        </div>\n      </div>\n      <div class=\"form-group m-r-15\" style=\"width: 135px\">\n        <div class=\"input-group date\">\n          <input type=\"text\" name=\"scheduled_facebook_wall_post[publish_at]\" class=\"timepicker form-control\" value=\"")
    ; __append(escapeFn( slot.time))
    ; __append("\">\n          <span class=\"input-group-addon\"><i class=\"fa fa-clock-o\"></i></span>\n        </div>\n      </div>\n    </div>\n  </td>\n</tr>\n")
  }
  return __output;

}