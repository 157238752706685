class ParamsRenderer {
  constructor(replaceDuplicates = true) {
    this.replaceDuplicates = replaceDuplicates;
  }

  // https://github.com/sindresorhus/query-string/blob/master/index.js
  parseParams(str) {
    if (typeof str !== 'string') {
      return {}
    }
    str = str.trim().replace(/^(\?|#|&)/, '')

    if (!str) {
      return {}
    }

    return str.split('&').reduce((function (ret, param) {
      const parts = param.replace(/\+/g, ' ').split('=');
      // Firefox (pre 40) decodes `%3D` to `=`
      // https://github.com/sindresorhus/query-string/pull/37
      let key = parts.shift()
      let val = parts.length > 0 ? parts.join('=') : undefined
      key = decodeURIComponent(key)
      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      val = val === undefined ? null : decodeURIComponent(val)
      if (!ret.hasOwnProperty(key)) {
        ret[key] = val
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val)
      } else {
        ret[key] = [
          ret[key],
          val
        ]
      }
      return ret;
    }), {})
  }

  render(url, params) {
    for (let paramName in params) {
      const paramValue = params[paramName];
      url = this.addParam(url, paramName, paramValue)
    }
    return url
  }

  addParam(url, parameterName, parameterValue, atStart) {
    let cl, urlhash;
    if (atStart == null) {
      atStart = false
    }
    if (url.indexOf('#') > 0) {
      cl = url.indexOf('#')
      urlhash = url.substring(url.indexOf('#'), url.length)
    } else {
      urlhash = ''
      cl = url.length
    }

    const sourceUrl = url.substring(0, cl)
    const urlParts = sourceUrl.split('?')
    let newQueryString = ''

    if (urlParts.length > 1) {
      const parameters = urlParts[1].split('&')
      let i = 0;
      while (i < parameters.length) {
        const parameterParts = parameters[i].split('=')
        if (!(this.replaceDuplicates && (parameterParts[0] === parameterName))) {
          if (newQueryString === '') {
            newQueryString = '?'
          } else {
            newQueryString += '&'
          }
          newQueryString += parameterParts[0] + '=' + (parameterParts[1] ? parameterParts[1] : '')
        }
        i++
      }
    }

    if (newQueryString === '') {
      newQueryString = '?'
    }

    if (atStart) {
      newQueryString = '?' + parameterName + '=' + parameterValue + (newQueryString.length > 1 ? '&' + newQueryString.substring(1) : '')
    } else {
      if ((newQueryString !== '') && (newQueryString !== '?')) {
        newQueryString += '&'
      }
      newQueryString += parameterName + '=' + (parameterValue ? parameterValue : '')
    }

    return urlParts[0] + newQueryString + urlhash;
  }

  removeMissingParts(url, mask) {
    let cl, urlhash
    if (url.indexOf('#') > 0) {
      cl = url.indexOf('#');
      urlhash = url.substring(url.indexOf('#'), url.length)
    } else {
      urlhash = ''
      cl = url.length
    }

    const sourceUrl = url.substring(0, cl)
    const urlParts = sourceUrl.split('?')
    let newQueryString = ''

    if (urlParts.length > 1) {
      const parameters = urlParts[1].split('&')
      let i = 0
      while (i < parameters.length) {
        const parameterParts = parameters[i].split('=')
        if (!Array.from(mask).includes(parameterParts[0])) {
          if (newQueryString === '') {
            newQueryString = '?'
          } else {
            newQueryString += '&'
          }
          newQueryString += parameterParts[0] + '=' + (parameterParts[1] ? parameterParts[1] : '')
        }
        i++
      }
    }

    return urlParts[0] + newQueryString + urlhash
  }
}

export default ParamsRenderer
