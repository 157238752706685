import React from 'react'

export default ({children, collapseId}) => {
  return(
    <div className="panel-heading">
      <div className="post-title">
        <h4 className="panel-title">
          <a
            className="accordion-toggle accordion-toggle-styled" data-parent="#html_modal_accordion"
            data-toggle="collapse" href={`#${collapseId}`}><i className="fa fa-caret-down"/>{' '}{children}</a>
        </h4>
      </div>
    </div>
  )
}
