import {initializeTags, refreshTags, toggleAddButton} from '../tags_helper'
import ScheduledFacebookWallPostsHelper from '../../helpers/scheduled_facebook_wall_posts_helper'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#scheduled-facebook-wall-posts-index, #network-scheduled-facebook-wall-posts-index').length > 0) {
    let modal = $('#tag-post-modal')
    const network = $('#network-hidden-container').data('network')
    const page = $('#page-hidden-container').data('page')
    const tagsElement = modal.find('.facebook-post-tags-input')
    const postsHelper = new ScheduledFacebookWallPostsHelper()


    const onModalShow = e => {
      let facebookPostId = $('#tag-post-modal').attr('data-scheduled-facebook-wall-post-id')
      if (facebookPostId) {
        $.getJSON(`/tags?scheduled_facebook_wall_post_id=${facebookPostId}`).then(data => {
          _.each(data, tag => $('input.facebook-post-tags-input').tagsinput('add', tag.name))
          $('input.facebook-post-tags-input').tagsinput('refresh')
        })
      }

      initializeTags('input.facebook-post-tags-input')
    }

    const onModalHide = e => {
      $('#tag-post-modal').removeAttr('data-facebook-post-id')
      $('input.facebook-post-tags-input').tagsinput('removeAll')
      $('input.facebook-post-tags-input').tagsinput('refresh')
      Array.from($('.facebook-post-checkbox')).map((checkbox) => $(checkbox).prop('checked', false))
    }

    const formatData = isAddTags => {
      const tagsValues = modal.find('.facebook-post-tags-input').val()
      const data = {
        tags: tagsValues
      }
      if (isAddTags) {
        data.ids = postsHelper.selectedPostsIds()
      }
      if (network) {
        data.network_id = network.id
      }
      if (page) {
        data.page_id = page.id
      }
      return data
    }

    const clickHandler = e => {
      modal = $('#tag-post-modal')
      const tags = modal.find('.facebook-post-tags-input').val()
      const postId = $('#tag-post-modal').attr('data-scheduled-facebook-wall-post-id')
      if (postId) {
        refreshTags(`/scheduled_facebook_wall_post_tags/${postId}`, formatData(false), 'PUT', modal)
      } else {
        refreshTags("/scheduled_facebook_wall_post_tags", formatData(true), 'POST', modal)
        Array.from($('.scheduled-facebook-wall-post-checkbox')).map((checkbox) => $(checkbox).prop('checked', false))
      }
    }

    const addTagThrottled = _.throttle(clickHandler, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-message').click(addTagThrottled)

    modal.on('show.bs.modal', onModalShow)
    modal.on('hide.bs.modal', onModalHide)
    tagsElement.change(toggleAddButton)
  }
})
