import {initializeDateWithoutTimepicker, initializeTimepicker, mergeDateWithTime} from "../../helpers/datetimehelper";
import {initializeSelect2} from "../../helpers/select2_helper";
import {initializeTags} from "../tags_helper";
import {clearUtms, onDestinationPageIdChange, utmsForDestinationPages} from '../../helpers/utms'
import * as UTM_SNIPPET_MASK from "underscore/underscore-esm";

document.addEventListener('DOMContentLoaded', () => {
  if ($('#scheduled-facebook-wall-posts-index, #network-scheduled-facebook-wall-posts-index').length > 0) {
    const modal = $('#add-facebook-wall-post-modal')
    const basePath = URI().path()
    const form = $("#new_scheduled_facebook_wall_post")
    const tagsElement = modal.find('.facebook-post-tags-input')
    let page = $('#page-hidden-container').data('page')
    const destinationPages = modal.find('#scheduled_facebook_wall_post_destination_page_ids')

    const openModalAndUnsubscribe = e => {
      modal.modal('show');
      modal.off('hidden.bs.modal', openModalAndUnsubscribe)
    };

    const formatPage = () => {
      if (_.isUndefined($('#page-hidden-container').data('page'))) {
        const element = modal.find('#scheduled_facebook_wall_post_source_page_id').find(':selected')
        return page = {id: element.val(), name: element.text()}
      } else {
        return page = $('#page-hidden-container').data('page')
      }
    }

    const scheduledFacebookWallPostAttributes = () => {
      let scheduled_facebook_wall_post = {}
      modal.find('.scheduled-facebook-wall-post-attributes').each(() => {
        const formData = $('#new_scheduled_facebook_wall_post').serializeArray()
        const data = {}
        for (let field of Array.from(formData)) {
          const name_without_prefix = field.name.match(new RegExp(/\[(.+)\]/))
          if (name_without_prefix) {
            data[name_without_prefix[1]] = field.value
          }
        }
        scheduled_facebook_wall_post = data
      })

      scheduled_facebook_wall_post["publish_at"] = mergeDateWithTime(modal)
      return scheduled_facebook_wall_post
    }

    const onModalShow = e => {
      initializeTimepicker('.timepicker')
      initializeDateWithoutTimepicker('.date-without-time-picker')
      initializeTags('input.facebook-post-tags-input')
      initializeSelect2(destinationPages, true)
      formatPage()
    }

    const addClickHandler = e => {
      const inputs = modal.find('#new_scheduled_facebook_wall_post :input')
      const errors = {}
      const reset = $(e.target).data('reset')

      // find empty required fields and add errors
      for (let i = 0, end = inputs.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
        const input_element = $(inputs[i])
        if (!input_element.val() && input_element.attr('required')) {
          const model_field_name = input_element.attr('name').match(new RegExp(/\[([^\]]+)\]/))[1]
          errors[model_field_name] = ["Can't be blank"]
        }
      }

      if (!$.isEmptyObject(errors)) {
        form.render_form_errors('scheduled_facebook_wall_post', errors)
      } else {
        const data = {
          scheduled_facebook_wall_post: scheduledFacebookWallPostAttributes(modal),
          destination_page_ids: destinationPages.val(),
          source_page_id: page.id,
          tags: tagsElement.val(),
          exclude_utms: $('#exclude_utms').is(":checked"),
          utms: utmsForDestinationPages(modal, true),
          to_draft: $(this).hasClass('btn-add-to-draft')
        }

        $.ajax({
          url: basePath,
          method: 'POST',
          data,
          success() {
            form.clear_form_errors()
            if (reset) {
              clearForm()
              onDestinationPageChange({target: destinationPages})
            } else {
              modal.on('hidden.bs.modal', openModalAndUnsubscribe)
              notifyUser('Auto-generated from previous post')
            }
            modal.modal('hide')
          },
          error(response) {
            form.render_form_errors('scheduled_facebook_wall_post', response.responseJSON)
          }
        })
      }
    }

    $.fn.render_form_errors = (model_name, errors) => {
      form.clear_form_errors()

      $.each(errors, (field, messages) => {
        const inputs = form.find('input, select, textarea')
        const input = inputs.filter(idx => {
          const name = $(inputs[idx]).attr('name')
          if (name) {
            return !_.isEmpty(name.match(new RegExp(model_name + '\\[' + field + '\\(?')))
          }
        })
        input.closest('.form-group').addClass('has-error')
        input.parent().append('<span class="help-block">' + $.map(messages, m => m.charAt(0).toUpperCase() + m.slice(1)).join('<br />') + '</span>')
      })
    }

    $.fn.clear_form_errors = () => {
      $(form).find('.form-group.has-error').removeClass('has-error')
      $(form).find('span.help-block').remove()
    }

    const notifyUser = text => $.notify(text, {
        position: 'top center',
        className: 'info',
        autoHideDelay: 3500
      }
    )

    const clearForm = () => {
      form.trigger('reset')
      clearUtms(modal)
      tagsElement.tagsinput('removeAll')
    }

    const onDestinationPageChange = e => {
      let selectedSourcePages
      if (page) {
        selectedSourcePages = [{id: page.id, name: page.subject_name}]
      } else {
        selectedSourcePages = []
      }

      onDestinationPageIdChange(modal, selectedSourcePages, $(e.target))
    }

    const tagInputTypehead = () => $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')

    const checkUTMsInLink = e => {
      const link = $(e.target).val();
      const uri = URI(link);
      const excludeUtmsActive = UTM_SNIPPET_MASK.some((element, index, array) => uri.hasQuery(element))
      if (excludeUtmsActive) {
        $('#exclude_utms').prop('checked', true)
      }
    }

    modal.find('#scheduled_facebook_wall_post_link').change(checkUTMsInLink)
    modal.find('#scheduled_facebook_wall_post_source_page_id').on('change', formatPage)
    destinationPages.change(onDestinationPageChange)
    onDestinationPageChange({target: destinationPages})
    modal.on('show.bs.modal', onModalShow)
    tagsElement.change(tagInputTypehead)
    const addThrottled = _.throttle(addClickHandler, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-add-facebook-post, .modal-footer .btn-add-to-draft').click(addThrottled)
  }
})
