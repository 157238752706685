import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const PreviewTab = () => {
  const {is_mobile} = useSelector(state => state)
  const dispatch = useDispatch()

  const mobileWidth = 400
  const mobileHeight = 400

  useEffect(() => {
    if(is_mobile) {
      tinymce.activeEditor.theme.resizeTo(mobileWidth, mobileHeight)
    } else {
      const modal = document.getElementById('html-modal')
      const editor = modal.querySelector('.mce-tinymce')
      if(editor === null) return

      const newWidth = editor.closest('.tab-content').offsetWidth
      tinymce.activeEditor.theme.resizeTo(newWidth, 800)
    }
  }, [is_mobile])

  return (
    <div className="tab-pane fade in active" id="preview">
      <div className="form-group">
        <div className="control-label toggle-btn-container">
          <div className="toggle-btn">
            <input className="toggle-btn-checkbox" id="mobile_preview_toggle" type="checkbox"
                   checked={is_mobile} onChange={() => dispatch({type: 'SET_MOBILE'})}/>
            <label className="toggle-btn-label" htmlFor="mobile_preview_toggle">
              <span className="toggle-btn-inner"/>
              <span className="toggle-btn-switch"/>
            </label>
          </div>
          <label className="toggle-span" htmlFor="mobile_preview_toggle">
            <span className="translation_missing">Mobile</span>
          </label>
        </div>
        <textarea className='hidden' id="html_modal_textarea"/></div>
    </div>
  )
}

export default PreviewTab
