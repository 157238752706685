$.fn.renderFormErrors = (model_name, errors, form) => {
  form.clearFormErrors()
  $.each(errors, (field, messages) => {
    let input
    const inputs = form.find('input, select, textarea')

    input = inputs.filter(idx => {
      let name
      name = $(inputs[idx]).attr('name')
      if (name) {
        return !!name.match(new RegExp(model_name + '\\[' + field + '\\(?'))
      }
    })
    // NOTE: Show error on wrong input instead of muting it and skipping
    if (input.length === 0) {
      input = form.find('input, select, textarea').first()
    }
    input.closest('.form-group').addClass('has-error')
    input.closest('.form-group').append('<span class="help-block">' + $.map(messages, m => m.charAt(0).toUpperCase() + m.slice(1)).join('<br />') + '</span>')
  })
}

$.fn.clearFormErrors = () => {
  $('.modal-body').find('.form-group').removeClass('has-error')
  $('.modal-body').find('span.help-block').remove()
}

validateFrom = (form, modal) => {
  let emptyFields, errors
  emptyFields = false
  errors = {}
  _.each(modal.find('input[type=date], input[type=text], select'), input => {
    let input_element, model_field_name
    input_element = $(input)
    if (!input_element.val() && input_element.attr('required')) {
      model_field_name = input_element.attr('name').match(new RegExp(/\[([^\]]+)\]/))[1]
      errors[model_field_name] = ["Can't be blank"]
      emptyFields = true
    }
  })
  if (emptyFields) {
    form.renderFormErrors('scheduled_facebook_wall_post', errors, form)
  }
  return emptyFields
}
