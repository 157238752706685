import BaseViewStats from './base_view_stats'
import IpReputationsHelper from './ip_reputations_helper'

class MetricsViewStats extends BaseViewStats {
  constructor(data) {
    super(data)
  }

  formatData() {
    return super.formatData()
  }

  redrawCharts() {
    if (!_.isEmpty(this.data)) {
      const {
        userReportedSpamRatioData,
        feedbackLoopData,
        deliveryErrorsData,
        domainReputationData,
        authenticationData,
        encryptionData
      } = this.formatData()

      Chartkick.charts['chart-1'].updateData(userReportedSpamRatioData)
      Chartkick.charts['chart-2'].updateData(domainReputationData)
      Chartkick.charts['chart-3'].updateData(feedbackLoopData)
      Chartkick.charts['chart-4'].updateData(authenticationData)
      Chartkick.charts['chart-5'].updateData(encryptionData)
      Chartkick.charts['chart-6'].updateData(deliveryErrorsData)
      this.drawIpReputations()
    }
  }

  drawIpReputations() {
    const data = []
    const chart = Chartkick.charts['ip-reputations']
    const options = chart.getOptions()
    this.ip_reputations.forEach(rep => {
      if (this.domain_id !== 0) {
        const chartData = rep.data.map(({data, name}) => {
          return {
            library: {
              label: `${name}`,
              stack: `Stack ${rep.domain_id}`
            },
            data: data,
          }
        })
        chart.updateData(chartData)

        let newOptions = {
          library: {
            animation: {
              duration: 1500
            },
            onClick: (evt, array) => this.onBarClick(evt, array, this.domain_id)
          }
        }
        chart.setOptions(_.merge(options, newOptions))
        return
      }
      const {name} = this.domains.find(domain => domain.id === rep.domain_id)
      let newData = this.formatIpReputationsData(rep.data, `IP reputations (${name})`, false)

      newData = {
        ...newData,
        library: {
          label: `IP reputations (${name})`,
          stack: `Stack ${rep.domain_id}`
        }
      }

      if (!_.isEmpty(newData.data)) data.push(newData)
    })

    if (this.domain_id !== 0) return

    chart.updateData(data)
    let newOptions = {
      library: {
        animation: {
          duration: 1500
        },
        onClick: (evt) => this.lineOnClick(evt, chart, this.domain_id)
      }
    }
    chart.setOptions(_.merge(options, newOptions))
  }

  lineOnClick(evt, chart, domain_id) {
    const array = chart.chart.getElementsAtEventForMode(evt, 'nearest', {intersect: true}, true)
    if (_.isEmpty(array)) return

    let index = array[0].index
    let datasetIndex = array[0].datasetIndex

    let chartData = chart.getData()

    let time = chartData[datasetIndex].data[index][0]
    let date = new Date(time)

    let formattedDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
    $.ajax({
      async: true,
      type: 'GET',
      url: `sender_views/ip_reputations?date=${formattedDate}&domain_id=${domain_id}`,
      success: (data) => this.appendSampleIps(data, date)
    })
  }

  appendSampleIps({ip_reputations}, date) {
    const keys = Object.keys(ip_reputations)
    keys.forEach((key, idx) => {
      const {name} = this.domains.find(domain => domain.id === parseInt(key))
      let clearOld = false

      if (idx === 0) {
        clearOld = true
      }

      const helper = new IpReputationsHelper(`#accordion`, {ip_reputations: ip_reputations[key]}, key, date, clearOld)
      helper.setLabelPostfix(name)
      helper.drawSampleIps()
    })
  }

  onBarClick(evt, array, domain_id) {
    if (_.isEmpty(array[0])) return

    let date = array[0]._model.label
    $.ajax({
      async: true,
      type: 'GET',
      url: `sender_views/ip_reputations?date=${date}&domain_id=${domain_id}`,
      success: (data) => new IpReputationsHelper(`#accordion`, data, domain_id, new Date(date), true).drawSampleIps()
    })
  }
}

export default MetricsViewStats
