class PaginatorHelper {
  changePostPerPage(event) {
    event.preventDefault();
    const uri = new URI
    const search = uri.search(true)
    search['per_page'] = $(event.target).val()
    const newUrl = uri.search(search).toString()
    window.location.href = newUrl
  }

  changePaginationLink(event) {
    event.preventDefault()
    const uri = new URI
    const search = uri.search(true)
    const currentSearch = URI($(this).attr('href')).search(true)
    search['page'] = currentSearch['page']
    search['status'] = currentSearch['status']
    window.location.href = uri.search(search).toString()
  }
}

export default PaginatorHelper
