module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("    <tr data-facebook-post-id=")
    ; __append(escapeFn( postId ))
    ; __append(" id=\"facebook-post-id-")
    ; __append(escapeFn( postId ))
    ; __append("\" class='facebook-post-form'>\n  <td class=\"col-md-7\">")
    ; __append(escapeFn( postTitle ))
    ; __append("</td>\n  <td class=\"col-md-4\">\n      <div class=\"form-inline\" style='position: relative;'>\n         <div class='form-group m-r-15'  style=\"width: 135px\">\n            <div class='input-group date'>\n              <input type=\"text\" name=\"scheduled_facebook_wall_post[publish_at]\" id=\"publish_at_date_")
    ; __append(escapeFn( postId ))
    ; __append("\" class=\"form-control  date-without-time-picker\">\n              <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n            </div>\n         </div>\n         <div class='form-group m-r-15'  style=\"width: 135px\">\n            <div class='input-group date'>\n              <input type=\"text\" name=\"scheduled_facebook_wall_post[publish_at]\" id=\"publish_at_time_")
    ; __append(escapeFn( postId ))
    ; __append("\" class=\"timepicker form-control\">\n              <span class=\"input-group-addon\"><i class=\"fa fa-clock-o\"></i></span>\n            </div>\n         </div>\n      </div>\n  </td>\n  <td class=\"col-md-1 p-t-17 p-r-15\">\n    <i class=\"fa fa-close text-danger remove-selected-post\"></i>\n  </td>\n</tr>\n")
  }
  return __output;

}