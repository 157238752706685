class IpReputationsHelper {
  constructor(accordion_id, data, domain_id, date, clear_old) {
    this.accordion_id = accordion_id
    this.ip_reputations = data.ip_reputations
    this.domain_id = domain_id
    this.date = date
    this.ip_reputations_names = ['HIGH', 'MEDIUM', 'LOW', 'BAD']
    this.clear_old = clear_old
    this.postfix = ''
  }

  changeLink(t, name) {
    let link = t.querySelector('a')
    let month_name = this.date.toLocaleDateString('en-US', { month: 'long'} )
    let innerHTML = `${name.toUpperCase()} reputation IP's on ${_.capitalize(month_name)} ${this.date.getDate()}`

    if (this.postfix !== '') {
      innerHTML = `${name.toUpperCase()} reputation IP's on ${_.capitalize(month_name)} ${this.date.getDate()} (${this.postfix})`
    }

    link.innerHTML = innerHTML
    link.setAttribute('aria-controls', `collapse-${name.toLowerCase()}-rep-${this.domain_id}`)
    link.href = `#collapse-${name.toLowerCase()}-rep-${this.domain_id}`
  }

  changePanelCollapse(t, name) {
    let panel_collapse = t.querySelector('.panel-collapse')
    panel_collapse.id = `collapse-${name.toLowerCase()}-rep-${this.domain_id}`
    panel_collapse.setAttribute('aria-labelledby', `heading-${name.toLowerCase()}-rep-${this.domain_id}`)
  }

  buildSampleIpsTable(t, index) {
    let sample_ips_markup = this.ip_reputations[index].sample_ips.map(ip => {
      return `<tr><th>${ip}</th></tr>`
    }).join('');

    t.querySelector('.panel-body').innerHTML = `
        <table class="table">
          <tbody>
            ${sample_ips_markup}
          </tbody>
        </table>
        `
    ;
  }

  drawSampleIps() {
    let accordion_elem = document.querySelector(this.accordion_id)
    if (this.clear_old) accordion_elem.innerHTML = ''

    this.ip_reputations_names.forEach(name => {
      let t = document.querySelector('#accordion-tab-template').content
      let index = _.findIndex(this.ip_reputations, { 'reputation': name.toLowerCase() })
      if(index === -1) return

      t.querySelector('.panel-heading').id = `heading-${name.toLowerCase()}-rep-${this.domain_id}`
      this.changeLink(t, name)
      this.changePanelCollapse(t, name)
      this.buildSampleIpsTable(t, index)

      let clone = document.importNode(t, true)
      accordion_elem.appendChild(clone)
    })
  }

  setLabelPostfix(postfix) {
    this.postfix = postfix
  }
}

export default IpReputationsHelper
