import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {sendToEsp} from '../../../redux/actions/EmailHtmlBuilder'
import ConfirmHtmlModalRow from './ConfirmHtmlModalRow'

const ConfirmHtmlModal = () => {
  const {esp_data, scheduled_time, selected_lists, segment, campaign, sender_name, subject, preheader} = useSelector(state => state)
  const {lists, segments, campaigns, admin} = esp_data

  const dispatch = useDispatch()

  const handleConfirm = () => {
    dispatch(sendToEsp())
  }

  const formatData = (items, id) => {
    const item = items.find(item => item.id === parseInt(id))

    if (item === undefined) return

    if(admin) {
      return item.organization.name
    } else {
      return item.title
    }
  }

  const findLists = () => {
    const selected_lists_names = []
    selected_lists.forEach(id => {
      lists.forEach(l => {
        if(l.id === id) {
          selected_lists_names.push(`${l.title}(${l.recipient_number})`)
        }
      })
    })
    return(selected_lists_names)
  }

  return (
    <>
      <div aria-labelledby="confirm-html-modal" className="modal fade" data-backdrop="static" id="confirm-html-modal"
           role="dialog" tabIndex={-1}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="send-share-partners-modal-label">Confirm</h4>
            </div>
            <div className="modal-body">
              <div id="confirmation_container">
                <div>
                  <ConfirmHtmlModalRow label='Schedule' value={scheduled_time} />
                  <ConfirmHtmlModalRow label='Lists' value={findLists().join(', ')} />
                  <ConfirmHtmlModalRow label='Segment' value={formatData(segments, segment)} />
                  <ConfirmHtmlModalRow label='Campaign' value={formatData(campaigns, campaign)} />
                  <div className='row'>
                    <div className='small-12 columns'>
                      <div className='gmailTable'>
                        <div className='gmailRow'>
                          <div className='checkbox'>
                            <div>
                            </div>
                          </div>
                          <div className='star'>
                            <img src='https://ssl.gstatic.com/ui/v1/star/star4.png'/>
                          </div>
                          <div className='senderName'>
                            {sender_name}
                          </div>
                          <div className='subjectLineAndPreHeader'>
                            <div>
                              <span className='SL'>
                                {subject}
                                </span>
                              <span className='pre'> - {preheader} </span>
                            </div>
                          </div>
                          <div className='time'>
                            <div>10:00 am</div>
                          </div>
                          <div className='clear'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-white btn-close-confirm" data-dismiss="modal" type="button">Close</button>
              <button className="btn btn-success btn-confirm-html-email" type="button" onClick={handleConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmHtmlModal
