import {App} from '../apps'

const findListElements = (event) => {
  let listElement = $(event.target).closest('ul')
  let textToFind = $(event.target).val().toLowerCase()
  let liElements = $($(listElement).find('li'))
  $(liElements.filter(function () {
    return $(this).text().toLowerCase().indexOf(textToFind) < 0 && $(this).text()
  })).css({display: 'none'})
  $(liElements.filter(() => {
    return $(this).text().indexOf(textToFind) >= 0
  })).css({display: 'list-item'})
}

document.addEventListener('DOMContentLoaded', () => {
  App.initSidebar()
  App.initTopMenu()
  if (typeof (localStorage) !== 'undefined') {
    let targetContainer = $('#page-container')
    let sidebarClass = 'page-sidebar-minified'
    let sidebarKey = 'SIDEBAR_MINIFY_STATUS'

    if (localStorage.getItem(sidebarKey)) {
      targetContainer.addClass(sidebarClass)
    } else {
      targetContainer.removeClass(sidebarClass)
    }

    $('#sidebar .sidebar-minify-btn').click(e => {
      if (targetContainer.hasClass(sidebarClass)) {
        localStorage.setItem(sidebarKey, true)
      } else {
        localStorage.removeItem(sidebarKey)
      }
    })
  }

  $('.facebook-page-search').on('change keyup paste', findListElements)
  let selector = 'a[href$="' + window.location.pathname + '"]:first'
  $(selector).closest('li').addClass('active')
  $($(selector).closest('ul')).closest('li').addClass('active')
})
