import 'ejs'
import SmartScheduleHelper from "./smart_schedule_helper"
import {removeSelectedElement} from "./select2_helper";

const UTM_SNIPPET_MASK = ['utm_campaign', 'utm_term', 'utm_source', 'utm_medium']

const UTM_INPUTS_TEMPLATE = require('../pages/templates/inputs.jst.ejs')

export const clearUtms = (modal) => {
  return modal.find('.page-utms').remove()
}

export const findFbUtmTemplate = (modal, sourcePage, destinationPage, row) => {
  let results
  $.getJSON("/fb_utm_templates/find", {
    source_page_id: sourcePage.id,
    destination_page_id: destinationPage.id
  }).then(response => {
    let key, value
    row = modal.find(".page-utms[data-page-destination-id='" + destinationPage.id + "'][data-page-source-id='" + sourcePage.id + "']")
    results = []
    for (key in response) {
      value = response[key]
      results.push(row.find("input[name='" + key + "']").val(value))
    }
  })
  return results
}

export const utmsForDestinationPages = (modal, withoutSourcePage) => {
  let utms = {}
  const pageUtms = modal.find('.page-utms')
  pageUtms.each(idx => {
    let data, destinationPageId, field, formData, i, len, sourcePageId, that
    that = $(pageUtms[idx])

    sourcePageId = that.data('pageSourceId')
    destinationPageId = that.data('pageDestinationId')
    formData = []

    _.each(that.find('input'), element => {
      formData.push({name: $(element).attr('name'), value: $(element).val()})
    })

    data = {}
    if (withoutSourcePage === true) {
      for (i = 0, len = formData.length; i < len; i++) {
        field = formData[i]
        data[field.name] = field.value
      }
      utms[$(this).data('pageDestinationId')] = data
    } else {
      for (i = 0, len = formData.length; i < len; i++) {
        field = formData[i]
        data[field.name] = field.value
      }
      utms[sourcePageId] || (utms[sourcePageId] = {})
      utms[sourcePageId][destinationPageId] = data
    }
  })

  return utms
}

export const formSelectedDestinationPages = selectedElement => {
  let selectedDestinationPages = []
  _.each(selectedElement.find(':selected'), c => {
    selectedDestinationPages.push({
      id: $(c).val(),
      name: $(c).text()
    })
  })

  return selectedDestinationPages
}

export const onDestinationPageIdChange = (modal, selectedPages, selectedElement) => {
  let destinationPage, i, j, k, len, len1, len2, ref, results, row, selectedDestinationPages, sourcePage, that,
    utmsContainer
  clearUtms(modal)

  that = selectedElement
  utmsContainer = modal.find('.utms')

  selectedDestinationPages = formSelectedDestinationPages(that)

  let selectedPagePairs = []
  for (i = 0, len = selectedPages.length; i < len; i++) {
    sourcePage = selectedPages[i]
    for (j = 0, len1 = selectedDestinationPages.length; j < len1; j++) {
      destinationPage = selectedDestinationPages[j]
      selectedPagePairs.push([sourcePage, destinationPage])
    }
  }

  let helper = new SmartScheduleHelper()
  helper.renderTimeSlots(modal, selectedDestinationPages)

  results = []
  for (k = 0, len2 = selectedPagePairs.length; k < len2; k++) {
    ref = selectedPagePairs[k], sourcePage = ref[0], destinationPage = ref[1]
    row = UTM_INPUTS_TEMPLATE({
      sourcePage: sourcePage,
      destinationPage: destinationPage
    })
    utmsContainer.append(row)
    results.push(findFbUtmTemplate(modal, sourcePage, destinationPage))
  }

  $(utmsContainer).find('.remove-selected-post').click(e => {
    row = $(e.target).closest('tr')
    removeSelectedElement(that, row.data().pageDestinationId)
    row.remove()
  })


  if (selectedDestinationPages.length === 0) {
    modal.find('#page-utm-table').addClass('hidden')
  } else {
    modal.find('#page-utm-table').removeClass('hidden')
  }

  return results
}

// TEMPORARY FIX
export const onDestinationPageIdChangeSmartSchedule = (modal, selectedPages, selectedElement) => {
  let destinationPage, i, j, k, len, len1, len2, ref, results, row, selectedDestinationPages, sourcePage, that,
    utmsContainer
  clearUtms(modal)
  $(selectedElement).attr('disabled', true)
  $(selectedElement).closest('.col-md-10').spin('small')

  that = selectedElement
  selectedDestinationPages = []
  utmsContainer = modal.find('.utms')
  selectedDestinationPages = formSelectedDestinationPages(that)

  let selectedPagePairs = []
  for (i = 0, len = selectedPages.length; i < len; i++) {
    sourcePage = selectedPages[i]
    for (j = 0, len1 = selectedDestinationPages.length; j < len1; j++) {
      destinationPage = selectedDestinationPages[j]
      selectedPagePairs.push([sourcePage, destinationPage])
    }
  }

  let helper = new SmartScheduleHelper()
  helper.renderTimeSlots(modal, selectedDestinationPages)

  results = []
  for (k = 0, len2 = selectedPagePairs.length; k < len2; k++) {
    ref = selectedPagePairs[k], sourcePage = ref[0], destinationPage = ref[1]
    row = UTM_INPUTS_TEMPLATE({
      sourcePage: sourcePage,
      destinationPage: destinationPage
    })
    utmsContainer.append(row)
    results.push(findFbUtmTemplate(modal, sourcePage, destinationPage))
  }

  $(utmsContainer).find('.remove-selected-post').click(e => {
    row = $(e.target).closest('tr')
    removeSelectedElement(that, row.data().pageDestinationId)
    row.remove()
  })

  if (selectedDestinationPages.length === 0) {
    modal.find('#page-utm-table').addClass('hidden')
  } else {
    modal.find('#page-utm-table').removeClass('hidden')
  }

  return results
}
