import 'bootstrap-tagsinput/src/bootstrap-tagsinput'
import moment from 'moment'

document.addEventListener('DOMContentLoaded', () => {
  $('.datepicker').datetimepicker({
    disabledTimeIntervals: [true],
    format: 'YYYY-MM-DD HH:mm:ss z',
    allowInputToggle: true
  })

  $('.datetimepicker-with-default').on('click', (e) => {
    let that = $(e.target.closest('div'))
    let defaultValue = that.data('default')

    if (defaultValue) {
      if (that.data('datepicker')) return
      that.data('datepicker', true)

      let maxDate = that.data('maxDate')

      let pickerParams = {
        disabledTimeIntervals: [true],
        format: 'YYYY-MM-DD hh:mm A',
        defaultDate: defaultValue,
        allowInputToggle: true
      }

      that.datetimepicker(pickerParams);
      if (maxDate) {
        that.data("DateTimePicker").maxDate(maxDate)
      }

      that.data("DateTimePicker").show()
    }
  })


  $($('.date-picker').closest('div.input-group')).on('click', (e) => {
    let that = $(e.target)

    let utcOffset = $("#user_timezone").val()
    let defaultDate = moment().utcOffset(utcOffset).add(5, 'm')

    let pickerParams = {
      disabledTimeIntervals: [true],
      format: 'YYYY-MM-DD HH:mm:ss z',
      defaultDate: defaultDate,
      allowInputToggle: true
    }

    let maxDate = that.data('maxDate');
    if (maxDate) {
      pickerParams['maxDate'] = maxDate
    }

    $(that.closest('div.input-group')).datetimepicker(pickerParams)
    $(that.closest('div.input-group')).data("DateTimePicker").show()
  })

  $($('.date-picker-date-only').closest('div.input-group')).datetimepicker({
    disabledTimeIntervals: [true],
    format: 'YYYY-MM-DD',
    allowInputToggle: true
  })

  $('.datepicker-bottom').datetimepicker({
    disabledTimeIntervals: [true],
    widgetPositioning: {
      vertical: 'bottom'
    },
    format: 'YYYY-MM-DD HH:mm:ss z',
    allowInputToggle: true
  })

  $('.datepicker-bottom-date-only').datetimepicker({
    disabledTimeIntervals: [true],
    widgetPositioning: {
      vertical: 'bottom'
    },
    format: 'YYYY-MM-DD',
    allowInputToggle: true
  })

  $('input.tagsinput').tagsinput('items')
})
