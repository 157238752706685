class DailyStatDecorator {
  numberWithDelimiter(numberWithoutDelimiter, delimiter) {
    const number = numberWithoutDelimiter + ''
    delimiter = delimiter || ','
    const split = number.split('.')
    split[0] = split[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + delimiter)
    return split.join('.')
  }

  styleTotalData(total, keys) {
    const formatTotal = {}
    for (let key of Array.from(keys)) {
      const value = total[key]
      switch (key) {
        case 'average_engagement_rate':
          formatTotal.average_engagement_rate = (value * 100).toFixed(2) + '%'
          break
        case 'average_reach':
          formatTotal.average_reach = this.numberWithDelimiter(value.toFixed(0))
          break
        case 'average_fb_clicks':
          formatTotal.average_fb_clicks = this.numberWithDelimiter(value.toFixed(0))
          break;
        case 'average_ctr':
          formatTotal.average_ctr = (value * 100).toFixed(2) + '%'
          break
        case 'percentage_of_fan_count':
          formatTotal.percentage_of_fan_count = (value * 100).toFixed(2) + '%'
          break
        default:
          formatTotal[key] = this.numberWithDelimiter(value)
      }
    }
    return formatTotal
  }
}

export default DailyStatDecorator
