import 'ejs'
import moment from 'moment'
import {initializeDateWithoutTimepicker, initializeTimepicker} from './datetimehelper'

class SmartScheduleHelper {
  constructor() {
    this.SMART_SCHEDULE_TIME_ROW_TEMPLATE = require('../pages/templates/smart_time_row.jst.ejs')
    this.initializeTimeSlots = this.initializeTimeSlots.bind(this)
  }

  addNewTimeSlot = (row, page, slot) => {
    const rowTemplate = this.SMART_SCHEDULE_TIME_ROW_TEMPLATE({
      pageName: page.name,
      pageId: page.id,
      slot: slot
    })

    $(row).find('.slots-table').append(rowTemplate)
  }

  updateTimeSlot = (row, page, slot) => {
    const pageRow = $(row).find(`tr[data-destination-page-id='${page.id}']`)[0]
    $(pageRow).find('.timepicker').val(slot.time)
    $(pageRow).find('.date-without-time-picker').val(slot.date)
    $(pageRow).data('slotId', slot.id)
  }

  initializeRowTimeSlot = (row, slot, helper, modal, page, isUpdate) => {
    if (isUpdate == null) {
      isUpdate = false
    }
    const formatSlot = {}

    if (_.isUndefined(slot)) {
      const utcOffset = $("#user_timezone").val()
      formatSlot.date = moment().utcOffset(utcOffset).add(5, 'm').format('YYYY-MM-DD')
      formatSlot.time = moment().utcOffset(utcOffset).add(5, 'm').format('h:mm A')
    } else {
      formatSlot.date = slot.date;
      formatSlot.time = slot.time;
      formatSlot.id = slot.id;
    }
    if ($(modal).hasClass('smart-schedule-modal')) {
      if (isUpdate) {
        helper.updateTimeSlot(row, page, formatSlot)
      } else {
        helper.addNewTimeSlot(row, page, formatSlot)
      }
    }

    initializeTimepicker($(row).find('.timepicker'));
    initializeDateWithoutTimepicker($(row).find('.date-without-time-picker'));
  }

  initializeTimeSlots = (slots, page, modal) => {
    _.each(modal.find('.selected-facebook-post-row'), (row, index) => {
      this.initializeRowTimeSlot(row, slots[index], this, modal, page)
    })
  }

  findFreeTimeSlot = (modal, destinationPage) => {
    const data = {
      destination_page_id: destinationPage.id,
      number_of_slots: modal.find('.selected-facebook-post-row').length
    }

    $.getJSON('/free_slots', data).then(response => {
      this.initializeTimeSlots(response, destinationPage, modal)
      const destinationPageSelector = modal.find('#scheduled_facebook_wall_post_destination_page_ids')
      destinationPageSelector.attr('disabled', false)
      $(destinationPageSelector).closest('.col-md-10').spin(false)
    })
  }

  findRandomFreeTimeSlot = (modal, destinationPage, row) => {
    const occupiedSlotIds = []
    _.each(modal.find('.slots-table'), function (postTable) {
      const postRow = $(postTable).find(`tr[data-destination-page-id='${destinationPage.id}']`)[0]
      if ($(postRow).data('slotId')) {
        occupiedSlotIds.push($(postRow).data('slotId'))
      }
    })

    const data = {
      destination_page_id: destinationPage.id,
      occupied_slot_ids: occupiedSlotIds,
      is_random: true
    }

    $.getJSON('/free_slots', data).then(response => {
      this.initializeRowTimeSlot(row, response[0], helper, modal, destinationPage, true)
    })
  }

  toggleVisibleElements = (modal, destinationPages) => {
    const heading = modal.find('.panel-default .panel-heading');
    if (destinationPages.length === 0) {
      modal.find('.destination-page-blank').removeClass('hidden');
      modal.find('.slots-table').addClass('hidden');
      heading.removeClass('contain-slots');
    } else {
      modal.find('.destination-page-blank').addClass('hidden');
      modal.find('.slots-table').removeClass('hidden');
      heading.addClass('contain-slots');
    }
  }

  renderTimeSlots = (modal, destinationPages) => {
    $(modal).find('.slots-table tbody').html('');
    this.toggleVisibleElements(modal, destinationPages);
    modal.find('.selected-facebook-post-row .slots-table tbody').html('');
    Array.from(destinationPages).map(page => this.findFreeTimeSlot(modal, page))
  }
}

export default SmartScheduleHelper
