import React from 'react'

const ConfirmHtmlModalRow = ({label, value}) => {
  return(
    <div className='form-group row m-b-15'>
      <label className='control-label top col-md-2 text-right'>{label}</label>
      <div className='col-md-10'>
        <div className='row m-l-2 selected-facebook-post-row'>
          <p style={{whiteSpace: "pre-line"}}>
            {value}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ConfirmHtmlModalRow
