import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import {initializeDateWithoutTimepicker, initializeTimepicker, mergeDateWithTime} from '../../helpers/datetimehelper'
import {
  formSelectedDestinationPages,
  onDestinationPageIdChangeSmartSchedule,
  utmsForDestinationPages
} from '../../helpers/utms'
import {removeSelectedPost} from '../../helpers/schedule_post_helper'
import {initializeSelect2} from '../../helpers/select2_helper'
import {initializeTags} from '../tags_helper'
import SmartScheduleHelper from '../../helpers/smart_schedule_helper'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#facebook-posts-index').length > 0) {
    const SCHEDULE_ROW_TEMPLATE = require('../templates/smart_schedule_row.jst.ejs')
    let selectedFacebookPosts = []
    const page = $('#page-hidden-container').data('page')
    const form = $('#schedule-post')
    let shortLinks = false
    const modal = $('#smart-schedule-facebook-wall-post-modal')
    const destinationPageSelect = modal.find('#scheduled_facebook_wall_post_destination_page_ids')
    const tagsElement = modal.find('.facebook-post-tags-input')
    const facebookPostsHelper = new FacebookPostsHelper()

    const renderScheduleRow = facebookPost => SCHEDULE_ROW_TEMPLATE({
      postTitle: facebookPost.name,
      postId: facebookPost.id
    })

    const selectedPostsSchedule = modal => {
      const result = {}
      const destinationPageIds = destinationPageSelect.val()
      _.each(modal.find('.slots-table'), function (postTable) {
        const postId = $(postTable).data('facebook-post-id')
        result[postId] = {}
        _.each($(postTable).find('tbody tr'), function (postRow) {
          const pageId = $(postRow).data('destination-page-id')
          result[postId][pageId] = mergeDateWithTime(postRow)
        })
      })
      return result
    }

    const updateScheduleTable = modal => {
      const postsContainer = modal.find('#accordion')

      postsContainer.html("")

      for (let facebookPost of Array.from(selectedFacebookPosts)) {
        postsContainer.append(renderScheduleRow(facebookPost))
      }

      const destinationPages = formSelectedDestinationPages(destinationPageSelect)
      const helper = new SmartScheduleHelper()
      helper.renderTimeSlots(modal, destinationPages)

      modal.find('.remove-selected-post').click(removeSelectedPost)

      const reorderThrottled = _.throttle(reorderClickHandler, 2500, {'trailing': false})
      modal.find('.btn-reorder').click(reorderThrottled)
    }

    const onModalShow = e => {
      const params = {q: {id_in: facebookPostsHelper.selectedPostsIds()}};
      $.getJSON(`/pages/${page.id}/facebook_posts`, params).then(data => {
        let el
        selectedFacebookPosts = data
        initializeSelect2(modal.find('#scheduled_facebook_wall_post_destination_page_ids'), false)
        updateScheduleTable(modal)
        initializeTags('input.facebook-post-tags-input')
        for (el of Array.from(modal.find('.timepicker'))) {
          initializeTimepicker(el)
        }

        for (el of Array.from(modal.find('.date-without-time-picker'))) {
          initializeDateWithoutTimepicker(el)
        }
      })
    }

    const sendClickHandler = e => {
      const hasErrors = validateFrom(form, modal)
      if (!hasErrors) {
        const data = {
          posts: selectedPostsSchedule(modal),
          destination_page_ids: destinationPageSelect.val(),
          tags: tagsElement.val(),
          utms: utmsForDestinationPages(modal, true),
          short_links: shortLinks,
          to_draft: $(this).hasClass('btn-send-to-draft')
        }
        $.ajax({
          url: `/pages/${page.id}/facebook_posts/schedule_wall_post`,
          method: 'POST',
          data,
          success() {
            form.clearFormErrors();
            $('.facebook-post-checkbox').change(toggleSendButtons)
            // $(checkbox).prop('checked', false) for checkbox in $('.facebook-post-checkbox')
            toggleSendButtons()
            modal.modal('hide')
          },
          error(response) {
            const forms = modal.find('.facebook-post-form')
            _.each(forms, function (facebookDateForm) {

              const formElement = $(facebookDateForm)
              formElement.renderFormErrors('scheduled_facebook_wall_post',
                response.responseJSON[formElement.attr('data-facebook-post-id')],
                formElement)
            })
          }
        })
      }
    }

    const tagInputTypehead = () => {
      $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')
    }

    const reorderClickHandler = e => {
      const destinationPages = formSelectedDestinationPages(destinationPageSelect)
      const helper = new SmartScheduleHelper()
      const postRow = $(e.target).closest('.selected-facebook-post-row')
      Array.from(destinationPages).map((destinationPage) => {
        helper.findRandomFreeTimeSlot(modal, destinationPage, postRow)
      })
    }

    destinationPageSelect.change(e => {
      const selectedSourcePages = [{id: page.id, name: page.subject_name}];
      onDestinationPageIdChangeSmartSchedule(modal, selectedSourcePages, $(e.target))
    })
    tagsElement.change(tagInputTypehead)

    modal.on('show.bs.modal', onModalShow)
    modal.find('.btn-short-links').click(e => shortLinks = !shortLinks)

    const scheduleThrottled = _.throttle(sendClickHandler, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-to-facebook, .modal-footer .btn-send-to-draft').click(scheduleThrottled)
  }
})
