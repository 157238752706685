document.addEventListener('DOMContentLoaded', () => {
  if ($('#posts-index').length > 0) {
    const toggleSendButton = () => {
      const sendButton = $('.btn-writers-send')
      if ($('.send-writer-checkbox:checked').length > 0) {
        sendButton.removeClass('disabled')
      } else {
        sendButton.addClass('disabled')
      }
    }

    const selectedPostIds = () => {
      const ids = _.map($('.send-writer-checkbox:checked'), c => {
        return $(c).closest('tr').data('post-id')
      })
      return ids
    }

    $('.send-writer-checkbox').change(toggleSendButton)

    $('body').on('mouseover', "[data-toggle='tooltip']", e => {
      const that = $(this)
      that.tooltip({html: true})
      that.tooltip('show')
    })

    $('.send-all-writer-checkbox').click(e => {
      const checked = $(e.target).prop('checked')
      Array.from($('.send-writer-checkbox')).map((checkbox) => {
        $(checkbox).prop('checked', checked).trigger('change')
      })
    })

    $('#send-writers-modal').on('show.bs.modal', e => {
      const modal = $(e.target)

      $.getJSON('/posts', {q: {id_in: selectedPostIds()}}).then(data => {
        const links = _.map(data, post => post.link)

        const message = modal.find('.modal-body .message-text')
        let text = "Here are your next story assignments!\n\n"
        text += links.join("\n\n")
        message.text(text)
      })
    })

    $('#send-writers-modal .modal-footer .btn-send-message').click(e => {
      const modal = $('#send-writers-modal')

      const writerId = modal.find('.modal-body .writer-selector').val()
      const subject = modal.find('.modal-body .message-subject').val()
      const message = modal.find('.modal-body .message-text').val()

      $.ajax({
        url: '/posts/send_email_to_writer',
        method: 'POST',
        data: {
          writer_id: writerId,
          message,
          subject,
          ids: selectedPostIds()
        },
        dataType: 'script',
        complete() {
          toggleSendButton();
          return modal.modal('hide')
        }
      })
    })

    $('.post-filter-form').keypress(e => {
      if (e.keyCode === 13) {
        return false
      }
    })

    if ($('.published-period option:selected').val() === '') {
      $('.publish-dates').removeClass('hidden')
    }

    $('.published-period').change(e => {
      if ($('.published-period option:selected').val() === '') {
        $('.publish-dates').removeClass('hidden')
      } else {
        $('.publish-dates').addClass('hidden')
      }
    })

    $('#template').select2({dropdownAutoWidth: true})
  }
})
