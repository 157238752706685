import React from 'react'
import {useDispatch} from 'react-redux'

const EspOneSelect = ({items, labelName, admin, includeBlank, name}) => {
  const dispatch = useDispatch()

  const handleChange = (e) => {
    dispatch({type: `SET_${labelName.toUpperCase()}`, payload: e.target.value})
  }

  return (
    <div className={`form-group row m-b-15 select-${name}`}>
      <label className="control-label col-md-2 text-right">
        {labelName}
      </label>
      <div className="col-md-4">
        <select name={name} id={`${name}_id`} className="form-control" onChange={handleChange}>
          {includeBlank && <option value=''>{'Choose ' + labelName}</option>}
          {items.map((item, id) => {
            return (
              <option key={id} value={item.id}>{admin ? item.organization.name : item.title}</option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default EspOneSelect
