import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

const TextTab = () => {
  const {is_mobile} = useSelector(state => state)
  const dispatch = useDispatch()
  return (
    <div className="tab-pane fade" id="text">
      <div className="form-group">
        <div className="control-label toggle-btn-container">
          <div className="toggle-btn">
            <input className="toggle-btn-checkbox" id="mobile_text_toggle"
                   type="checkbox" checked={is_mobile} onChange={() => dispatch({type: 'SET_MOBILE'})}/>
            <label className="toggle-btn-label" htmlFor="mobile_text_toggle">
              <span className="toggle-btn-inner"/>
              <span className="toggle-btn-switch"/>
            </label>
          </div>
          <label className="toggle-span" htmlFor="mobile_text_toggle">
            <span className="translation_missing">Mobile</span>
          </label>
        </div>
        <textarea className="form-control" id="message_text" readOnly="readonly" rows="15"/>
      </div>
    </div>
  )
}

export default TextTab
