document.addEventListener('DOMContentLoaded', () => {
  const header = $('#header')
  const resize = () => {
    if (window.innerWidth > 767) {
      $('#sidebar').css('padding-top', header.height() + 'px')

    }
    else {
      $('#sidebar').css('padding-top', '15px');

    }
    $('.page-header-fixed').css('padding-top', header.height() + 'px')
  }

  $(window).resize(resize)
  resize()
})
