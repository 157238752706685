import React from 'react'
import Navigation from './Navigation'
import PreviewTab from './PreviewTab'
import HtmlTab from './HtmlTab'
import TextTab from './TextTab'
import InboxPreviewTab from './InboxPreviewTab'

const Preview = () => {
  return (
    <>
      <Navigation/>
      <div className="tab-content">
        <PreviewTab/>
        <HtmlTab/>
        <TextTab/>
        <InboxPreviewTab/>
      </div>
    </>
  )
}

export default Preview
