class FacebookPostFiltersSerializer {
  constructor(form) {
    this.form = form
    this.formatFilters = []
  }

  isFormControl(input) {
    return $(input).hasClass('form-control')
  }

  notEmpty(input) {
    return $(input).val() !== ""
  }

  isDateInput(input) {
    return $(input).closest('.form-group').hasClass('date-form-group')
  }

  formOtherFilters() {
    const serializer = this;
    const filters = $(this.form).find('input').filter(() => {
      return serializer.isFormControl(this) && serializer.notEmpty(this) && !serializer.isDateInput(this)
    })

    for (let filter of Array.from(filters)) {
      const value = $(filter).val()
      const label = $($(filter).closest('.form-group')).find('label').text()
      this.formatFilters.push({label, value})
    }
  }

  formDates() {
    const serializer = this;
    const filters = $(this.form).find('input').filter(() => {
      return serializer.isFormControl(this) && serializer.notEmpty(this) && serializer.isDateInput(this);
    })
    for (let filter of Array.from(filters)) {
      const value = $(filter).val().match(/(.*)( -)/)[1]
      const label = $($(filter).closest('.form-group')).find('label').text()
      this.formatFilters.push({label, value})
    }
  }

  formSelects() {
    const serializer = this;
    const filters = $(this.form).find('select').filter(function () {
      return serializer.isFormControl(this) && serializer.notEmpty(this)
    })

    for (let filter of Array.from(filters)) {
      const value = $(`#${$(filter).attr('id')} option[selected]`).text();
      const label = $($(filter).closest('.form-group')).find('label').text();
      this.formatFilters.push({label, value})
    }
  }

  perform() {
    this.formSelects();
    this.formDates();
    this.formOtherFilters();
    return JSON.stringify(this.formatFilters);
  }
}

export default FacebookPostFiltersSerializer
