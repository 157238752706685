import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {initializeSelect2} from '../../../javascripts/helpers/select2_helper'

const EspListsSelect = () => {
  const {lists} = useSelector(state => state.esp_data)

  useEffect(() => {
    initializeSelect2(document.querySelector('.select-lists').querySelector('select'), true)
  }, [])
  return (
    <div className="form-group row m-b-15 select-lists">
      <label className="control-label col-md-2 text-right">Lists
        <span>Use Ctrl-Click of Shift-Click to select multiple</span>
      </label>
      <div className="col-md-10">
        <select name="lists[]" id="list_ids" required="required"
                className="facebook-page-selector form-control col-md-6 select-col" multiple tabIndex="-1"
                aria-hidden="true">
          {lists.map((list, id) => <option key={id} value={list.id}>{`${list.title}(${list.recipient_number})`} </option>)}
        </select>
      </div>
    </div>

  )
}

export default EspListsSelect
