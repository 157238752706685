import {initializeDateWithoutTimepicker, initializeTimepicker, mergeDateWithTime} from '../../helpers/datetimehelper'
import {initializeSelect2} from '../../helpers/select2_helper'
import {initializeTags} from '../tags_helper'
import {clearUtms, onDestinationPageIdChange, utmsForDestinationPages} from '../../helpers/utms'
import * as UTM_SNIPPET_MASK from 'underscore/underscore-esm'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#facebook-posts-index').length > 0) {
    const modal = $('#add-facebook-wall-post-modal')
    const page = $('#page-hidden-container').data('page')
    const form = $("#new_scheduled_facebook_wall_post")
    const destinationPages = modal.find('#scheduled_facebook_wall_post_destination_page_ids')
    const tagsElement = modal.find('.facebook-post-tags-input')

    const openModalAndUnsubscribe = e => {
      modal.modal('show')
      return modal.off('hidden.bs.modal', openModalAndUnsubscribe)
    }

    const onModalShow = e => {
      initializeTimepicker('.timepicker')
      initializeDateWithoutTimepicker('.date-without-time-picker')
      initializeSelect2(destinationPages, true)
      initializeTags('input.facebook-post-tags-input')
    }

    const scheduledFacebookWallPostAttributes = () => {
      let scheduled_facebook_wall_post = {}
      modal.find('.scheduled-facebook-wall-post-attributes').each(() => {
        const formData = $('#new_scheduled_facebook_wall_post').serializeArray()
        const data = {}
        for (let field of Array.from(formData)) {
          const name_without_prefix = field.name.match(new RegExp(/\[(.+)\]/))
          if (name_without_prefix) {
            data[name_without_prefix[1]] = field.value
          }
        }
        scheduled_facebook_wall_post = data
      })
      scheduled_facebook_wall_post["publish_at"] = mergeDateWithTime(modal)
      return scheduled_facebook_wall_post
    }

    const addClickHandler = e => {
      const inputs = modal.find('#new_scheduled_facebook_wall_post :input')
      const errors = {}
      const reset = $(e.target).data('reset')

      // find empty required fields and add errors
      for (let i = 0, end = inputs.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
        const input_element = $(inputs[i])
        if (!input_element.val() && input_element.attr('required')) {
          const model_field_name = input_element.attr('name').match(new RegExp(/\[([^\]]+)\]/))[1]
          errors[model_field_name] = ["Can't be blank"]
        }
      }

      if (!$.isEmptyObject(errors)) {
        form.renderFormErrors('scheduled_facebook_wall_post', errors, form)
      } else {
        const data = {
          scheduled_facebook_wall_post: scheduledFacebookWallPostAttributes(modal),
          destination_page_ids: destinationPages.val(),
          source_page_id: page.id,
          tags: tagsElement.val(),
          exclude_utms: $('#exclude_utms').is(":checked"),
          utms: utmsForDestinationPages(modal, true),
          to_draft: $(this).hasClass('btn-add-to-draft')
        };

        $.ajax({
          url: `/pages/${page.id}/scheduled_facebook_wall_posts`,
          method: 'POST',
          data,
          success() {
            form.clearFormErrors()
            if (reset) {
              clearForm()
              onDestinationPageChange({target: destinationPages})
            } else {
              modal.on('hidden.bs.modal', openModalAndUnsubscribe)
              notifyUser('Auto-generated from previous post')
            }
            modal.modal('hide')
          },
          error(response) {
            form.renderFormErrors('scheduled_facebook_wall_post', response.responseJSON, form)
          }
        })
      }
    }

    const tagInputTypehead = () => {
      $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')
    }

    const clearForm = () => {
      form.trigger('reset')
      clearUtms(modal)
      tagsElement.tagsinput('removeAll')
    }

    const notifyUser = text => $.notify(text, {
      position: 'top center',
      className: 'info',
      autoHideDelay: 3500
    })

    const onDestinationPageChange = function (e) {
      const selectedSourcePages = [{id: page.id, name: page.subject_name}]
      onDestinationPageIdChange(modal, selectedSourcePages, $(e.target))
    }

    destinationPages.change(onDestinationPageChange)

    onDestinationPageChange({target: destinationPages})

    modal.on('show.bs.modal', onModalShow)

    const checkUTMsInLink = e => {
      const link = $(e.target).val();
      const uri = URI(link);
      const excludeUtmsActive = UTM_SNIPPET_MASK.some((element, index, array) => uri.hasQuery(element))
      if (excludeUtmsActive) {
        $('#exclude_utms').prop('checked', true)
      }
    }

    modal.find('#scheduled_facebook_wall_post_link').change(checkUTMsInLink)
    tagsElement.change(tagInputTypehead)

    const addThrottled = _.throttle(addClickHandler, 2500, {'trailing': false})

    modal.find('.modal-footer .btn-add-facebook-post, .modal-footer .btn-add-to-draft').click(addThrottled)
  }
})
