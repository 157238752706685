import {
  handleIEFullHeightContent,
  handleSlimScroll,
  handleUnlimitedTabsRender,
  handlePanelAction,
  handelTooltipPopoverActivation,
  handleToggleNavbarSearch,
  handleDismissNavbarSearch,
  handleToggleNavProfile,
  handleScrollToTopButton,
  handleAfterPageLoadAddClass,
} from '../apps'

document.addEventListener('DOMContentLoaded', () => {
  handleIEFullHeightContent()
  handleSlimScroll()
  handleUnlimitedTabsRender()
  handlePanelAction()
  handelTooltipPopoverActivation()
  handleScrollToTopButton()
  handleAfterPageLoadAddClass()
  handleToggleNavProfile()
  handleDismissNavbarSearch()
  handleToggleNavbarSearch()
})
