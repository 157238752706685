import moment from 'moment'
import 'ejs'
import {initializeTimepicker} from '../../helpers/datetimehelper'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#schedules-new, #schedules-edit, #schedules-update, #schedules-create').length > 0) {
    const utcOffset = $("#user_timezone").val()
    const defaultTime = moment().utcOffset(utcOffset).startOf('day')
    initializeTimepicker('.timepicker', defaultTime)

    const toggleCleanButton = (table, panel) => {
      const cleanButton = panel.find('.clean-day')
      if ((table.find('td').length >= 1) && cleanButton.hasClass('hidden')) {
        cleanButton.toggleClass('hidden')
      } else if ((table.find('td').length === 0) && !cleanButton.hasClass('hidden')) {
        cleanButton.toggleClass('hidden')
      }
    }

    const toggleCleanButtons = () => {
      _.each($('.slots-table'), table => {
        toggleCleanButton($(table), $(table).closest('.panel-collapse'))
      })
    }

    const togglePanelHeading = (panel) => {
      const heading = $(panel).find('.panel-heading')
      const table = $(panel).find('.slots-table')
      if (table.find('tr').length > 0) {
        heading.addClass('contain-slots')
      } else {
        heading.removeClass('contain-slots')
      }
    }

    const togglePanelHeadings = () => {
      _.each($('#accordion').find('.panel-default'), panel => {
        togglePanelHeading(panel)
      })
    }

    const changeStyle = (table, panel) => {
      togglePanelHeading(panel)
      toggleCleanButton(table, $(panel.find('.panel-collapse')))
    }

    const removeRow = (event) => {
      const table = $(event.target).closest('table')
      const panel = $(event.target).closest('.panel')
      const deletedSlotId = $(event.target).closest('tr').find('input').data('slotId')

      const deletedSlotIdsElement = panel.find('#deleted_slots')
      const value = deletedSlotIdsElement.val()
      const ids = JSON.parse(value)
      ids.push(deletedSlotId)
      deletedSlotIdsElement.val(JSON.stringify(ids))

      $(event.target).closest('tr').remove()
      changeStyle(table, panel)
    }

    const cleanTimeTable = (event) => {
      const panel = $(event.target).closest('.panel')
      const table = panel.find('tbody')
      table.html('')
      changeStyle(table, panel)
    }
    const TIME_ROW_TEMPLATE = require('../templates/schedule_time_row.jst.ejs')
    const addTimeSlot = (event) => {
      const panel = $(event.target).closest('.panel')
      const table = panel.find('tbody')
      const dayName = panel.data('day')
      const inputName = `schedule[days][${dayName}][new][]`
      table.append(TIME_ROW_TEMPLATE({inputName}))
      $('.remove-slot').click(removeRow)
      changeStyle(table, panel)
      initializeTimepicker('.timepicker', defaultTime)
    }

    $('.add-time').click(addTimeSlot)
    $('.remove-slot').click(removeRow)
    $('.clean-day').click(cleanTimeTable)
    toggleCleanButtons()
    togglePanelHeadings()
  }
})
