import PaginatorHelper from '../../helpers/paginator_helper'
import SelectedPostsHelper from '../../helpers/selected_posts_helper'
import FacebookPostsHelper from '../../helpers/facebook_posts_helper'


document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    const selectedPostsContainer = $('.container-selected-posts')
    const facebookPostsContainer = $('.container-facebook-posts')
    const paginatorHelper = new PaginatorHelper()
    const selectedPostsHelper = new SelectedPostsHelper()
    const clearSelectionBtn = $('.btn-clear-selected-posts')
    const deletePosts = $('.btn-delete-posts')
    const facebookPostsHelper = new FacebookPostsHelper()

    $('tbody .fa.fa-hashtag').click(e => {
      const facebook_post_id = $(this).closest('tr').attr('data-facebook-post-id')
      $('#tag-post-modal').attr('data-facebook-post-id', facebook_post_id)
      $('#tag-post-modal').modal('show')
    })

    $('.btn-generate-html').click(e => {
      const param_name = 'facebook_post_ids[]='
      const post_ids = facebookPostsHelper.selectedPostsIds()
      let params = post_ids.map(id => param_name + id)
      params = params.join('&')
      window.location.href = `../../email_html_builder?${params}`
    })

    const toggleFilters = (e) => {
      const moreFilters = $('.more-filters')
      if (moreFilters.hasClass('hidden')) {
        moreFilters.removeClass('hidden')
        $(e.target).text('Less')
      } else {
        moreFilters.addClass('hidden')
        $(e.target).text('More')
      }
    }

    $('#toogle-filters').click(toggleFilters)

    const toggleSendButtons = () => {
      const selectedRowInf = $('.selected-rows')
      const tagButton = $('.btn-tag-post')
      const scheduleButton = $('.btn-schedule-facebook-wall-post')
      const sendButton = $('.btn-share-partners-send')

      selectedRowInf.text(
        selectedPostsContainer.find('.facebook-post-checkbox:checked').length + ' rows selected'
      )

      if (selectedPostsContainer.find('.facebook-post-checkbox:checked').length > 0) {
        sendButton.removeClass('disabled')
        tagButton.removeClass('disabled')
        scheduleButton.removeClass('disabled')
        selectedRowInf.removeClass('disabled')
        clearSelectionBtn.removeClass('disabled')
        deletePosts.removeClass('disabled')
      } else {
        sendButton.addClass('disabled')
        tagButton.addClass('disabled')
        scheduleButton.addClass('disabled')
        selectedRowInf.addClass('disabled')
        clearSelectionBtn.addClass('disabled')
        deletePosts.addClass('disabled')
      }
    }

    toggleSendButtons()

    const clearSelectionThrottled = _.throttle(selectedPostsHelper.clearSelection, 2500, {'trailing': false})
    clearSelectionBtn.click(clearSelectionThrottled)

    $('.pagination a').on('click', paginatorHelper.changePaginationLink)


    $('body').on('change',
      '.container-facebook-posts .facebook-post-checkbox',
      selectedPostsHelper.toggleSelectedPost)

    $('.send-all-checkbox').click(e => {
      let checkboxes
      const that = $(e.target)
      const checked = that.prop('checked')
      const table = that.closest('table')
      if (checked) {
        checkboxes = table.find('.facebook-post-checkbox:not(:checked)')
      } else {
        checkboxes = table.find('.facebook-post-checkbox')
      }

      checkboxes.prop('checked', true).prop('checked', checked).trigger('change')
    })

    $('body').on('change', '.facebook-post-checkbox', toggleSendButtons)
    $('#per_page').change(paginatorHelper.changePostPerPage)

    // NOTE: for tinymce link and image edit
    $(document).on('focusin', e => {
      if ($(e.target).closest(".mce-window").length) {
        e.stopImmediatePropagation()
      }
    })
  }
})
