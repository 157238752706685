import React from 'react'
import {Provider} from "react-redux"
import configureStore from "../../packs/configureStore"
import Form from './Form'
import Preview from './preview/index'
import Footer from './footer/index'
import Header from './Header'

const EmailHtmlBuilder = ({facebook_posts}) => {
  const store = configureStore()
  return (
    <Provider store={store}>
      <div className="modal-content" id="html-modal">
        <div className="modal-header">
          <Header />

          <div className="modal-body">
            <div className="row border-between">
              <div className="col-md-4">
                <Form facebook_posts={facebook_posts}/>
              </div>
              <div className="col-md-8">
                <Preview/>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </Provider>
  )
}

export default EmailHtmlBuilder
