import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import {initializeTags} from '../tags_helper'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#facebook-posts-index').length > 0) {
    const modal = $('#tag-post-modal')
    const page = $('#page-hidden-container').data('page')
    const tagsElement = modal.find('.facebook-post-tags-input')
    const facebookPostsHelper = new FacebookPostsHelper()

    const onModalShow = e => {
      let facebookPostId = $('#tag-post-modal').attr('data-facebook-post-id');
      if (facebookPostId) {
        $.getJSON(`/tags?facebook_post_id=${facebookPostId}`).then(data => {
          _.each(data, tag => $('input.facebook-post-tags-input').tagsinput('add', tag.name))
          $('input.facebook-post-tags-input').tagsinput('refresh')
        })
      }

      initializeTags('input.facebook-post-tags-input')
    }

    const onModalHide = function (e) {
      $('#tag-post-modal').removeAttr('data-facebook-post-id')
      $('input.facebook-post-tags-input').tagsinput('removeAll')
      $('input.facebook-post-tags-input').tagsinput('refresh')
      toggleSendButtons()
    }

    const clearLastAddedTag = () => // clear typeahead, that shows last added tag
      $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')

    const refreshClickEvent = () => {
      $('tbody .fa.fa-hashtag').click(e => {
        const facebook_post_id = $(this).closest('tr').attr('data-facebook-post-id')
        $('#tag-post-modal').attr('data-facebook-post-id', facebook_post_id)
        $('#tag-post-modal').modal('show')
      })
      $('.facebook-post-checkbox').change(toggleSendButtons)
    }

    const updateTags = (tagsValues, facebookPostId) => $.ajax({
      url: `/facebook_post_tags/${facebookPostId}`,
      method: 'PUT',
      data: {
        tags: tagsValues,
        page_id: page.id
      },
      dataType: 'script',
      complete() {
        refreshClickEvent()
        modal.modal('hide')
      }
    })

    const addTags = tagsValues => $.ajax({
      url: "/facebook_post_tags",
      method: 'POST',
      data: {
        ids: facebookPostsHelper.selectedPostsIds(),
        tags: tagsValues,
        page_id: page.id
      },
      dataType: 'script',
      complete() {
        refreshClickEvent()
        modal.modal('hide')
      }
    })

    const addClickHandler = e => {
      const tagsValues = tagsElement.val()
      const facebookPostId = $('#tag-post-modal').attr('data-facebook-post-id')
      if (facebookPostId) {
        updateTags(tagsValues, facebookPostId)
      } else {
        addTags(tagsValues)
      }
    }

    modal.on('show.bs.modal', onModalShow)
    modal.on('hide.bs.modal', onModalHide)
    tagsElement.change(clearLastAddedTag)

    const addTagThrottled = _.throttle(addClickHandler, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-message').click(addTagThrottled)
  }
})
