document.addEventListener('DOMContentLoaded', () => {
  if ($('#users-edit, #users-new').length > 0) {
    const showFileName = () => {
      const input = $(this)
      const label = input.val().split(/(\\|\/)/g).pop()
      $('.selected-avatar-file').text(label)
    }

    $(document).on('change', '#user_avatar', showFileName)
  }
})
