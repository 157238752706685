import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {initializeSelect2} from '../../../javascripts/helpers/select2_helper'

const AbCampaignMessagesSelect = () => {
  const {ab_campaign_messages} = useSelector(state => state)

  useEffect(() => {
    initializeSelect2(document.querySelector('.select-ab-campaigns').querySelector('select'), true)
  }, [])
  return (
    <div className="form-group row m-b-15 select-ab-campaigns">
      <label className="control-label col-md-2 text-right">Messages
        <span>Use Ctrl-Click of Shift-Click to select multiple</span>
      </label>
      <div className="col-md-10">
        <select name="ab_campaign_messages[]" id="ab-campaign_ids" required="required"
                className="facebook-page-selector form-control col-md-6 select-col" multiple tabIndex="-1"
                aria-hidden="true">
          {ab_campaign_messages.map((item, id) => <option key={id} value={item.headline}>{item.headline}</option>)}
        </select>
      </div>
    </div>

  )
}

export default AbCampaignMessagesSelect
