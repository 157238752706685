import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import TinymceHelper from '../../helpers/tinymce_helper'
import moment from 'moment'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    const modal = $('#send-share-partners-modal')
    const networkId = $('.wrapper-networks').data('networkId')

    let network = {}
    let selectedPartner = null
    let selectedFacebookPosts = []
    let sharePartners = []
    const facebookPostsHelper = new FacebookPostsHelper()
    let shortLinks = false

    const changeSelectedPartner = partnerId => {
      selectedPartner = _.find(sharePartners, p => partnerId === p.id)
      updateSubjectAndMessage()
    }

    const updateSubjectAndMessage = () => {
      updateSubject()
      renderPreview()
    }

    const updateSubject = () => {
      const input = modal.find('#share_partner_subject')
      const date = moment().format('MMMM Do YYYY')
      const text = `${network.subject_name || ''} FB Posts for ${selectedPartner.name} - Date: ${date}`
      input.val(text)
    }

    $.getJSON(`/networks/${networkId}.json`).then(data => network = data)

    $.getJSON(`/share_partners?network_id=${networkId}`).then(data => {
      sharePartners = data
      if (sharePartners.length > 0) {
        const selectedSharePartnerId = modal.find('#share_partner_id').val()
        changeSelectedPartner(parseInt(selectedSharePartnerId))
      }
    })

    const onModalShow = e => $.getJSON(`/networks/${networkId}/facebook_posts`, {q: {id_in: facebookPostsHelper.selectedPostsIds()}}).then(data => {
      selectedFacebookPosts = data;
      if (!_.isEmpty(selectedPartner)) {
        updateSubjectAndMessage()
      }
    })

    const sendToSharePartner = e => {
      const subject = modal.find('#share_partner_subject').val()
      const message = modal.find('#share-partner-message-text').val()
      const from = modal.find('.modal-body .from-email-selector').val()

      $.ajax({
        url: `/networks/${networkId}/facebook_posts/send_email_to_share_partner`,
        method: 'POST',
        data: {
          share_partner_id: selectedPartner.id,
          message,
          html_message: tinymce.activeEditor.getContent(),
          subject,
          ids: facebookPostsHelper.selectedPostsIds(),
          from
        },
        dataType: 'script',
        complete() {
          modal.modal('hide')
        }
      })
    }

    const modalSharePartnerSelect = sharePartnerId => {
      $('#share_partner_cc').val(sharePartnerId)
      changeSelectedPartner(sharePartnerId)
    }

    // duplicate from html_send
    const renderPreview = () => {
      if (selectedFacebookPosts.length > 0) {
        const params = {
          ids: selectedFacebookPosts.map(post => post.id),
          share_partner_id: selectedPartner.id,
          email_template_id: $('#share_partner_email_template_id').val(),
          short_links: (shortLinks ? shortLinks : undefined)
        }
        tinymce.remove()
        $.ajax({
          url: `/networks/${network.id}/facebook_posts/preview_share_partner_email`,
          method: 'GET',
          data: params,
          dataType: 'text',
          complete(response) {
            const tinymceHelper = new TinymceHelper()
            tinymceHelper.initialize(modal, response.responseText, '#liquid_html')
          }
        })
      }
    }

    modal.on('show.bs.modal', onModalShow)

    const sendThrottled = _.throttle(sendToSharePartner, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-message').click(sendThrottled)

    modal.find('#share_partner_email_template_id').on('change', renderPreview)
    modal.find('#share_partner_id').change(e => {
      modalSharePartnerSelect(parseInt($(this).val()))
    })
    modal.find('.btn-short-links').click(e => {
      shortLinks = !shortLinks
      updateSubjectAndMessage()
    })

    $('.wrapper-networks').on('change', '.facebook-post-checkbox', toggleSendButtons)
  }
})
