import ScheduledFacebookWallPostsHelper from "../../helpers/scheduled_facebook_wall_posts_helper";

document.addEventListener('DOMContentLoaded', () => {
  if ($('#scheduled-facebook-wall-posts-index,' + '#network-scheduled-facebook-wall-posts-index').length > 0) {
    const searchForm = $('#scheduled_facebook_wall_post_search')
    const deleteButton = $('.btn-delete-scheduled-facebook-wall-posts')
    const editButton = $('.btn-edit-scheduled-facebook-wall-posts')
    const draftButton = $('.btn-draft-scheduled-facebook-wall-posts')
    const scheduleButton = $('.btn-schedule-scheduled-facebook-wall-posts')
    const scheduleModalButton = $('.btn-schedule-facebook-wall-post')
    const addTagButton = $('.btn-tag-post')
    const basePath = URI().path()
    const selectedWallPosts = []
    const edit_modal = $('#edit-wall-posts-modal')
    const postsHelper = new ScheduledFacebookWallPostsHelper()

    const selectedStatus = () => URI().query(true).status

    window.toggleActionButtons = () => {
      if ($('.scheduled-facebook-wall-post-checkbox:checked').length > 0) {
        deleteButton.removeClass('disabled')
        editButton.removeClass('disabled')
        draftButton.removeClass('disabled')
        scheduleButton.removeClass('disabled')
        scheduleModalButton.removeClass('disabled')
        addTagButton.removeClass('disabled')
      } else {
        deleteButton.addClass('disabled')
        editButton.addClass('disabled')
        draftButton.addClass('disabled')
        scheduleButton.addClass('disabled')
        scheduleModalButton.addClass('disabled')
        addTagButton.addClass('disabled')
      }
    }

    deleteButton.click(e => {
      if (confirm('Do you really want to remove this post?')) {
        return $.ajax({
          url: `${basePath}/batch_destroy`,
          method: 'DELETE',
          data: {
            ids: postsHelper.selectedPostsIds()
          },
          dataType: 'script',
          complete() {
            for (let checkbox of Array.from($('input[type=checkbox]'))) {
              $(checkbox).prop('checked', false)
            }
            return toggleActionButtons()
          }
        })
      }
    })

    draftButton.click(e => $.ajax({
      url: `${basePath}/batch_update_status`,
      method: 'POST',
      data: {
        ids: postsHelper.selectedPostsIds(),
        new_status: 'draft'
      },
      dataType: 'script',
      complete() {
        for (let checkbox of Array.from($('input[type=checkbox]'))) {
          $(checkbox).prop('checked', false)
        }
        toggleActionButtons()
      }
    }))

    scheduleButton.click(e => $.ajax({
      url: `${basePath}/batch_update_status`,
      method: 'POST',
      data: {
        ids: postsHelper.selectedPostsIds(),
        new_status: 'scheduled'
      },
      dataType: 'script',
      complete() {
        for (let checkbox of Array.from($('input[type=checkbox]'))) {
          $(checkbox).prop('checked', false)
        }
        toggleActionButtons()
      }
    }))

    $('.scheduled-facebook-wall-post-checkbox').change(toggleActionButtons)

    $('.all-scheduled-facebook-wall-posts-checkbox').click(e => {
      const checked = $(this).prop('checked');
      Array.from($('.scheduled-facebook-wall-post-checkbox')).map((checkbox) => $(checkbox).prop('checked', checked).trigger('change'))
    })

    $('tbody .fa.fa-hashtag').click(e => {
      const attrName = 'data-scheduled-facebook-wall-post-id'
      const modal = $('#tag-post-modal')
      modal.attr(attrName, $(this).closest('tr').attr(attrName))
      modal.modal('show')
    })
  }
})
