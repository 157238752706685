import FacebookPostsHelper from "../../helpers/facebook_posts_helper";

document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    const facebookPostsHelper = new FacebookPostsHelper()
    const networkId = $('.wrapper-networks').data('networkId')

    $('.btn-delete-posts').on('click', () => {
      if (confirm('Are you sure want to delete post from FB, but keep all data?')) {
        const post_ids = facebookPostsHelper.selectedPostsIds()
        const data = {ids: post_ids}

        $.ajax({
          type: 'POST',
          url: `/networks/${networkId}/facebook_posts/delete_facebook_posts`,
          data,
          dataType: "text",
          success(response) {
            window.location.reload()
          },
          error(response) {
            alert('Some problem occurred. Please try again!')
          }
        })
      }
    })
  }
})
