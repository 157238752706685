module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<tr class='page-utms' data-wall-post-id='")
    ; __append(escapeFn( wallPost.id ))
    ; __append("' >\n    <td class='col-md-3 p-r-0 p-l-10'>\n        ")
    ; __append(escapeFn( wallPost.name ))
    ; __append("\n    </td>\n    <td class='col-md-2 p-r-0 p-l-10'>\n        <input type='text' name='utm_campaign' class='utm facebook-utm-campaign form-control' placeholder='utm_campaign' value=\"")
    ; __append(escapeFn( utm.utm_campaign ))
    ; __append("\">\n    </td>\n    <td class='col-md-2 p-r-0 p-l-10'>\n        <input type='text' name='utm_medium' class='utm facebook-utm-medium form-control' placeholder='utm_medium' value=\"")
    ; __append(escapeFn( utm.utm_medium ))
    ; __append("\">\n    </td>\n    <td class='col-md-2 p-r-0 p-l-10'>\n        <input type='text' name='utm_source' class='utm facebook-utm-source form-control' placeholder='utm_source' value=\"")
    ; __append(escapeFn( utm.utm_source ))
    ; __append("\">\n    </td>\n    <td class='col-md-2 p-r-0 p-l-10'>\n       <input type='text' name='utm_term' class='utm facebook-utm-term form-control' placeholder='utm_term' value=\"")
    ; __append(escapeFn( utm.utm_term ))
    ; __append("\">\n    </td>\n    <td class=\"col-md-1 p-t-15 p-r-15\">\n      <i class=\"fa fa-close text-danger pull-right remove-selected-post\"></i>\n    </td>\n</tr>\n")
  }
  return __output;

}