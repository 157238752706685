const setDefaultFields = (data) => ({
  type: 'SET_DEFAULT_FIELDS',
  payload: data
})

export const fetchEmailHtmlSenders = () => {
  return dispatch => {
    axios.get('email_html_builder/email_html_senders').then(({data}) => {
      dispatch(setDefaultFields(data))
      $('.selectpicker').selectpicker('refresh')
    })
  }
}

export const fetchLinkParams = (link, followNumber) => {
  return (dispatch, getState) => {
    const {items, utm_medium, utm_campaign, utm_source, utm_term, simple_headline} = getState()
    const item = {
      value: link,
    }

    items[followNumber] = {
      ...items[followNumber],
      ...item
    }

    dispatch({type: 'SET_ITEMS', payload: [...items]})

    const main_headline = simple_headline && items.length > 2

    axios.get(`email_html_builder/link_params`, {
      params: {
        link: link,
        utm: {
          utm_medium,
          utm_campaign,
          utm_source,
          utm_term
        },
        main_headline
      }
    })
      .then(({data, items = getState()['items']}) => {
        const index = items.findIndex(elem => elem.value === link)
        if (index === -1) return

        items[index] = {
          ...items[index],
          ...data
        }

        dispatch({type: 'SET_ITEMS', payload: [...items]})
      })
      .catch(error => console.log(error))
  }
}

const setEspData = (data) => ({
  type: 'SET_ESP_DATA',
  payload: data
})

export const fetchEspData = () => {
  return (dispatch, getState) => {
    const {template_id, templates} = getState()

    if (template_id === null || templates === []) return

    const {sender} = templates.find(template => template.id === parseInt(template_id))

    axios.get('email_html_builder/esp_data.json', {
      params: {
        template_id: template_id
      }
    }).then(({data}) => {
      dispatch(setEspData(data))
    })
  }
}

export const publishToLink = (html, text_version) => {
  return (dispatch, getState) => {
    const {
      items,
      utm_source,
      utm_term,
      utm_campaign,
      utm_medium,
      sender_name,
      preheader,
      follow_up_text,
      template_id,
      subject
    } = getState()
    const utm = {
      utm_source,
      utm_term,
      utm_campaign,
      utm_medium
    }

    axios.post('email_html_builder/publish_to_link', {
      html,
      text_version,
      posts: items,
      template_id,
      sender_name,
      preheader,
      follow_up_text,
      utm,
      subject
    }).then(data => onPublishedEmail(template_id))
  }
}

const onPublishedEmail = (template_id) => {
  $.ajax({
    url: `/email_template_senders/${template_id}`,
    method: 'get',
    success: (e) => {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute("type", "hidden")
      a.setAttribute("target", "_blank")
      a.href = `/s/${e.uuid}`
      a.click()
      document.body.removeChild(a)
    }
  })
}

export const preview = (html) => {
  return (dispatch, getState) => {
    const {preheader, sender_name, subject, follow_up_text} = getState()

    $.ajax({
      url: "email_html_builder/preview",
      method: 'POST',
      data: {
        preheader,
        sender_name,
        html,
        subject,
        follow_up_text
      },
      dataType: 'html',
      success: (response) => {
        const newWindow = window.open()
        let document = new DOMParser().parseFromString(response, 'text/html')
        document.querySelector('iframe').src = 'data:text/html;charset=utf-8,' + escape(html)
        newWindow.document.write(document.documentElement.innerHTML)
        newWindow.document.close()
      }
    })
  }
}

export const markAsSent = () => {
  return (dispatch, getState) => {
    const {items, template_id} = getState()

    axios.post('email_html_builder/mark_as_sent', {
      posts: items,
      template_id
    }).then(data => {
      $('#html-modal').modal('hide')
    })
  }
}

export const addToAbCampaign = () => {
  return (dispatch, getState) => {
    const {
      ab_campaign_messages,
      headline,
      preheader,
      message_name,
    } = getState()

    const html = tinymce.activeEditor.getContent()

    const ab_item = {
      html,
      headline,
      preheader,
      message_name,
    }

    dispatch({type: 'SET_AB_CAMPAIGN_MESSAGES', payload: [...ab_campaign_messages, ab_item]})
  }
}

export const createAbCampaign = () => {
  return (dispatch, getState) => {
    const {ab_campaign_messages, campaign_name, template_id} = getState()
    dispatch(setError(''))

    const content = $('#content')
    content.spin('big')

    axios.post('email_html_builder/ab_campaign.json', {
      messages: ab_campaign_messages,
      campaign_name,
      template_id
    }).then(data => {
      content.spin(false)
      dispatch({type: 'SET_AB_CAMPAIGN_MESSAGES', payload: []})
    }).catch(({response}) => {
      content.spin(false)
      dispatch(setError(response.data.error))
    })
  }
}

export const createCampaign = () => {
  return (dispatch, getState) => {
    const {
      campaign_name,
      template_id,
      message_name,
      headline,
      preheader,
    } = getState()
    dispatch(setError(''))

    const html = tinymce.activeEditor.getContent()

    const content = $('#content')
    content.spin('big')

    axios.post('email_html_builder/campaign.json', {
      campaign_name,
      template_id,
      html,
      message_name,
      headline,
      preheader,
    }).then(data => {
      content.spin(false)
    }).catch(({response}) => {
      dispatch(setError(response.data.error))
    })
  }
}

const setError = (message) => {
  return {
    type: 'SET_ERROR',
    payload: message
  }
}
export const sendToEsp = () => {
  return (dispatch, getState) => {
    const {
      items,
      sender_name,
      template_id,
      subject,
      message_name,
      headline,
      preheader,
      selected_lists,
      campaign,
      segment,
      scheduled_time
    } = getState()
    dispatch(setError(''))

    const html = tinymce.activeEditor.getContent()
    const text = document.getElementById('message_text').value

    const modal = $('#confirm-html-modal')
    const parentModal = $('#send-html-modal')
    modal.spin('big')

    axios.post('email_html_builder/send_to_esp.json', {
      posts: items,
      template_id,
      html,
      text,
      subject,
      message_name,
      headline,
      preheader,
      sender_name,
      list_ids: selected_lists,
      campaign_id: campaign,
      segment_id: segment,
      scheduled_time
    }).then(data => {
      modal.spin(false)
      parentModal.find('.error-block').html('')
      modal.modal('hide')
      parentModal.modal('hide')
      $('#html-modal').modal('hide')
    }).catch(({response}) => {
      modal.spin(false)
      modal.modal('hide')
      parentModal.show()
      dispatch(setError(response.data.error))
    })
  }
}
