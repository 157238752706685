module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<tr class=\"time\">\n   <td class=\"time-cell\">\n      <div class=\"input-group date col-sm-8\">\n        <input type=\"text\" name=\"")
    ; __append(escapeFn( inputName ))
    ; __append("\" id=\"time\" value=\"\" class=\"timepicker form-control\">\n        <span class=\"input-group-addon\">\n            <i class=\"fa fa-clock-o\"></i>\n        </span>\n      </div>\n      <div class=\"close-icon-container col-sm-2 text-center pull-right\">\n        <i class=\"fa fa-close text-danger remove-slot\"></i>\n      </div>\n   </td>\n</tr>\n")
  }
  return __output;

}