module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"panel panel-default selected-facebook-post-row\" >\n   <div class=\"panel-heading \">\n      <div class='post-title'>\n            <h4 class=\"panel-title\">\n              <a class=\"accordion-toggle accordion-toggle-styled collapsed\" data-parent=\"#accordion\" data-toggle=\"collapse\" href=\"#collapse")
    ; __append(escapeFn( postId))
    ; __append("\">\n                <i class=\"fa fa-caret-down\"></i>\n                ")
    ; __append(escapeFn( postTitle))
    ; __append("\n              </a>\n            </h4>\n      </div>\n\n      <div class='button-container'>\n        <button class='btn btn-primary pull-right btn-reorder'>Reorder</button>\n      </div>\n   </div>\n   <div class=\"panel-collapse collapse\" id=\"collapse")
    ; __append(escapeFn( postId))
    ; __append("\">\n      <div class=\"panel-body\">\n         <p class='alert alert-warning destination-page-blank'>You haven't selected Destination Pages yet.</p>\n\n             <table class=\"table table-striped table-hover table-responsive slots-table hidden\" data-facebook-post-id=\"")
    ; __append(escapeFn( postId))
    ; __append("\">\n                <thead>\n                    <th class=\"col-md-7\">Destination Page</th>\n                    <th class=\"col-md-4\">To be posted on</th>\n                </thead>\n                <tbody></tbody>\n             </table>\n      </div>\n   </div>\n</div>\n")
  }
  return __output;

}