document.addEventListener('DOMContentLoaded', () => {
  let searchForm = $('.container-search-form')
  let buttons = searchForm.find('.buttons-row')
  let scrollHeight = $('#header').height() + (buttons.outerHeight() || 0)
  $('table.table-sticky-header').floatThead({
    position: 'absolute',
    responsiveContainer: (table) => {
      return table.closest('.container-scrollable')
    },
    top: (table) => {
      return scrollHeight
    },
    zIndex: 500
  })

  // sticky buttons

  searchForm.css({width: searchForm.outerWidth()})
  $(window).resize(() => {
    searchForm.css({width: 'auto'})
    searchForm.css({width: searchForm.outerWidth()})
  })

  $(window).bind('scroll', () => {
    if ($('.container-scrollable').length > 0) {
      if ($(window).scrollTop() > $('.container-scrollable').offset().top - scrollHeight) {
        buttons.css({position: 'fixed', top: $('#header').height()})
      } else {
        buttons.css({position: 'relative', top: ''})
      }
    }
  })
})
