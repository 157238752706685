import React from 'react'

const PopupInputField = ({name, defaultValue, label, handleChange}) => {
  return(
    <div className="w-100">
      <div className="self-center form-group">
        <label htmlFor={name}>{label}</label>
        <input name={name} type='text' defaultValue={defaultValue} className='form-control' onChange={handleChange}/>
      </div>
    </div>
  )
}
export default PopupInputField
