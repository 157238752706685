document.addEventListener('DOMContentLoaded', () => {
  $('.datatable').DataTable({
    searching: false,
    scrollY: 700,
    scrollX: 300,
    scrollCollapse: true,
    scroller: true,
    responsive: true
  })
})
