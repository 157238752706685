import {initializeDateWithoutTimepicker, initializeTimepicker, mergeDateWithTime} from '../../helpers/datetimehelper'
import {removeSelectedPost} from '../../helpers/schedule_post_helper'
import {
  clearUtms,
  formSelectedDestinationPages,
  onDestinationPageIdChangeSmartSchedule,
  utmsForDestinationPages
} from '../../helpers/utms'
import {initializeSelect2} from '../../helpers/select2_helper'
import {initializeTags} from '../tags_helper'
import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import SmartScheduleHelper from "../../helpers/smart_schedule_helper";

document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    const SCHEDULE_ROW_TEMPLATE = require('../templates/smart_schedule_row.jst.ejs')
    let modal = $('#smart-schedule-facebook-wall-post-modal')
    const networkId = $('.wrapper-networks').data('networkId')
    const form = $('#schedule-post')

    const facebookPostsHelper = new FacebookPostsHelper()
    const tagsElement = modal.find('.facebook-post-tags-input')
    const destinationPageSelect = modal.find('#scheduled_facebook_wall_post_destination_page_ids')
    let selectedFacebookPosts = []
    let selectedPages = []
    let shortLinks = false

    const selectedPostsSchedule = modal => {
      const result = {}
      _.each(modal.find('.slots-table'), postTable => {
        const postId = $(postTable).data('facebook-post-id')
        result[postId] = {}
        _.each($(postTable).find('tbody tr'), postRow => {
          const pageId = $(postRow).data('destination-page-id')
          result[postId][pageId] = mergeDateWithTime(postRow)
        })
      })
      return result
    }

    const reorderClickHandler = e => {
      const destinationPages = formSelectedDestinationPages(destinationPageSelect)
      const helper = new SmartScheduleHelper()
      const postRow = $(e.target).closest('.selected-facebook-post-row')
      Array.from(destinationPages).map((destinationPage) => {
        helper.findRandomFreeTimeSlot(modal, destinationPage, postRow)
      })
    }

    const renderScheduleRow = facebookPost => SCHEDULE_ROW_TEMPLATE({
      postTitle: facebookPost.name,
      postId: facebookPost.id
    })

    const updateScheduleTable = modal => {
      const postsContainer = modal.find('#accordion')

      postsContainer.html("")

      for (let facebookPost of Array.from(selectedFacebookPosts)) {
        postsContainer.append(renderScheduleRow(facebookPost))
      }

      modal.find('.remove-selected-post').click(removeSelectedPost)

      const destinationPages = formSelectedDestinationPages(destinationPageSelect)
      const helper = new SmartScheduleHelper()
      helper.renderTimeSlots(modal, destinationPages)

      const reorderThrottled = _.throttle(reorderClickHandler, 2500, {'trailing': false})
      modal.find('.btn-reorder').click(reorderThrottled)
    }

    const onModalShow = e => {
      modal = $($(e.relatedTarget).data('target'))
      modal.find('#scheduled_facebook_wall_post_destination_page_ids').val('')
      clearUtms(modal)

      const params = {q: {id_in: facebookPostsHelper.selectedPostsIds()}}
      $.getJSON(`/networks/${networkId}/facebook_posts`, params).then(data => {
        let el
        selectedFacebookPosts = data
        selectedPages = _.uniqBy(_.map(data, 'page'), 'id')
        updateScheduleTable(modal)
        initializeSelect2(modal.find('#scheduled_facebook_wall_post_destination_page_ids'), false)
        initializeTags('input.facebook-post-tags-input')
        for (el of Array.from(modal.find('.timepicker'))) {
          initializeTimepicker(el);
        }

        for (el of Array.from(modal.find('.date-without-time-picker'))) {
          initializeDateWithoutTimepicker(el)
        }
      })
    }

    const sendToFacebook = e => {
      const hasErrors = validateFrom(form, modal)
      if (!hasErrors) {
        const data = {
          posts: selectedPostsSchedule(modal),
          destination_page_ids: destinationPageSelect.val(),
          tags: tagsElement.val(),
          utms: utmsForDestinationPages(modal, false),
          short_links: shortLinks,
          to_draft: $(this).hasClass('btn-send-to-draft')
        }

        modal.spin('big')
        $.ajax({
          url: `/networks/${networkId}/facebook_posts/schedule_wall_post`,
          method: 'POST',
          data,
          success() {
            modal.spin(false)
            form.clearFormErrors()
            // $(checkbox).prop('checked', false) for checkbox in $('.facebook-post-checkbox')
            toggleSendButtons()
            modal.modal('hide')
          },
          error(response) {
            modal.spin(false)
            const forms = modal.find('.facebook-post-form')
            const json = response.responseJSON

            // NOTE: Remove all successfull posts and show form errors
            for (let facebookDateForm of Array.from(forms)) {
              const formElement = $(facebookDateForm)
              const postId = formElement.closest('.slots-table').attr('data-facebook-post-id');
              const failedPostIds = Object.keys(json)
              if (postId && !failedPostIds.includes(postId)) {
                formElement.remove()
              } else {
                formElement.renderFormErrors('scheduled_facebook_wall_post', json[postId], formElement)
              }
            }

            const pageSelector = modal.find('.destination-page-form')
            pageSelector.renderFormErrors('scheduled_facebook_wall_post', json['other'], pageSelector)
          }
        })
      }
    }

    const tagInputTypehead = () => $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')

    $('.wrapper-networks').on('change', '.facebook-post-checkbox', toggleSendButtons)
    tagsElement.change(tagInputTypehead)

    modal.on('show.bs.modal', onModalShow)
    modal.find('.btn-short-links').click(e => shortLinks = !shortLinks)
    destinationPageSelect.change(evt => {
      onDestinationPageIdChangeSmartSchedule(modal, selectedPages, $(this))
    })

    const scheduleThrottled = _.throttle(sendToFacebook, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-to-facebook, .modal-footer .btn-send-to-draft').click(scheduleThrottled)
  }
})
