import React from 'react'

const HtmlTab = () => {
  return (
    <div className="tab-pane fade" id="html">
      <textarea className="form-control" id="html_message_text" rows="15"/>
    </div>
  )
}

export default HtmlTab
