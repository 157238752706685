import React, { useEffect, useMemo } from 'react'
import PanelHeading from './PanelHeading'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

const EmailParametersForm = () => {
  const dispatch = useDispatch()
  const {
    initial_message_name,
    message_name,
    subject,
    preheader,
    follow_up_text,
    items,
    campaign_name,
    simple_headline,
  } = useSelector(state => state)

  useEffect(() => {
    if (items.length > 0) {
      dispatch({type: 'SET_PREHEADER', payload: items[0].description})
      dispatch({type: 'SET_HEADLINE', payload: items[0].title})
      updateMessageName()
    }
  }, [items])

  useEffect(() => {
    updateMessageName()
  }, [initial_message_name])

  useEffect(() => {
    const date = moment().format("MM-DD")

    dispatch({type: 'SET_INITIAL_MESSAGE_NAME', payload: date})
  }, [])

  const handleSubjectChange = useMemo(() => _.debounce((e) => {
    dispatch({type: 'SET_SUBJECT', payload: e.target.value})
  }, 500), [dispatch])

  const handleInitialMessageNameChange = useMemo(() => _.debounce((e) => {
    dispatch({type: 'SET_INITIAL_MESSAGE_NAME', payload: e.target.value})
  }, 500), [dispatch])

  const handleCampaignNameChange = useMemo(() => _.debounce((e) => {
    dispatch({type: 'SET_CAMPAIGN_NAME', payload: e.target.value})
  }, 500), [dispatch])

  const handlePreheaderChange = useMemo(() => _.debounce((e) => {
    dispatch({type: 'SET_PREHEADER', payload: e.target.value})
  }, 500), [dispatch])

  const handleFollowUpTextChange = useMemo(() => _.debounce((e) => {
    dispatch({type: 'SET_FOLLOW_UP_TEXT', payload: e.target.value})
  }, 500), [dispatch])

  const handleSimpleHeadlineChange = (e) => {
    dispatch({type: 'SET_SIMPLE_HEADLINE', payload: e.target.checked})
  }

  const updateMessageName = () => {
    const charCodeOffset = 65
    const lastItemNumber = items.length - 1
    let positionMark = ''
    const snippets = items.filter(item => item.type === 'html_snippet')
    let resultName = initial_message_name

    snippets.forEach((snippet) => {
      positionMark = snippet.followNumber == lastItemNumber ? 'Btm' : String.fromCharCode(snippet.followNumber + charCodeOffset)
      resultName += ` ${snippet.short_name}${positionMark}`
    })
    dispatch({type: 'SET_MESSAGE_NAME', payload: resultName})
  }

  return (
    <div className="panel panel-default">
      <PanelHeading collapseId={'email_params'}>
        EmailParameters
      </PanelHeading>
      <div className="panel-collapse collapse in" id="email_params">
        <div className="panel-body">
          <div className="form-group form-35-65">
            <label className="control-label" htmlFor="subject">Subject:</label>
            <input className="form-control" id="subject" type="text" defaultValue={subject}
                   onChange={handleSubjectChange}/>
          </div>
          <div className="form-group form-35-65">
            <label className="control-label" htmlFor="initial_message_name">Initial Message Name:</label>
            <input className="form-control" id="initial_message_name" type="text" defaultValue={initial_message_name}
                   onChange={handleInitialMessageNameChange}/>
          </div>
          <div className="form-group form-35-65">
            <label className="control-label" htmlFor="message_name">Result Message Name:</label>
            <input className="form-control" disabled id="message_name" type="text" defaultValue={message_name} />
          </div>
          <div className="flex-form align-center" style={{gap: '10px'}}>
            <label className="control-label" htmlFor="simple_headline" style={{width: 'auto'}}>Simple Headline:</label>
            <input
              type="checkbox"
              id="simple_headline"
              checked={simple_headline}
              onChange={handleSimpleHeadlineChange}
            />
        </div>
          <div className="form-group">
            <label className="control-label" htmlFor="campaign_name">Campaign Name:</label>
            <input className="form-control" id="campaign_name" type="text" defaultValue={campaign_name}
                   onChange={handleCampaignNameChange}/>
          </div>
        <div className="form-group">
          <label className="control-label" htmlFor="preheader">Preheader:</label>
            <input className="form-control" id="preheader" type="text" defaultValue={preheader}
                   onChange={handlePreheaderChange}/>
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="follow_up_text">Follow up text:</label>
            <input className="form-control" id="follow_up_text" type="text" defaultValue={follow_up_text}
                   onChange={handleFollowUpTextChange}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailParametersForm
