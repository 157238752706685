import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Liquid} from 'liquidjs'
import EmailHtmlBuilderTinymceHelper from '../../javascripts/helpers/email_html_builder_tinymce_helper'

const RenderPreview = () => {
  const {items, template_id, preheader, follow_up_text, templates, subject} = useSelector(state => state)
  const engine = new Liquid()
  const current_year = new Date().getFullYear()

  const refreshHtmlSource = () => {
    if (tinymce.get('html_modal_textarea') !== null) {
      document.getElementById('html_message_text').value = tinymce.get('html_modal_textarea').getContent()
    }
  }

  useEffect(() => {
    if (templates.length === 0 || template_id === null) return

    const idx = templates.findIndex(template => template.id === parseInt(template_id))
    const template = templates[idx].email_template
    const title = items[0] === undefined ? '' : items[0].title
    const template_params = {}

    template.email_template_params.forEach(param => {
      template_params[param.key] = param.value
    })

    const unsubscribe_link = template.unsubscribe_link || template_params['unsubscribe_link']
    engine.parseAndRender(template.liquid_source, {
      posts: items,
      title,
      preheader,
      subject,
      follow_up_text,
      ...template_params,
      unsubscribe_link,
      current_year
    })
      .then(data => {
        tinymce.remove()
        const tinymceHelper = new EmailHtmlBuilderTinymceHelper()
        const modal = $('#html-modal')
        document.getElementById('html_message_text').value = data
        tinymceHelper.initialize(modal, data, '#html_modal_textarea', refreshHtmlSource, 800)
      })

    engine.parseAndRender(template.liquid_text_source, {
      posts: items,
      title,
      preheader,
      subject,
      follow_up_text,
      current_year,
      ...template_params,
      unsubscribe_link
    })
      .then(data => {
        document.getElementById('message_text').value = data
      })
  }, [items, template_id, preheader, follow_up_text, templates])
  return <></>
}

export default RenderPreview
