import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {SortableElement} from 'react-sortable-hoc'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import PopupInputField from '../PopupInputField'
import {fetchLinkParams} from "../../../redux/actions/EmailHtmlBuilder";

const LinkInput = SortableElement(({followNumber, removeItem}) => {
  const items = useSelector(state => state.items)
  const [link, setLink] = useState(items[followNumber])
  const dispatch = useDispatch()

  useEffect(() => {
    setLink(items[followNumber])
  }, [items])
  const handleChange = _.debounce((event) => {
    event.preventDefault()

    setLink({
      ...link,
      value: event.target.value
    })

    dispatch(fetchLinkParams(event.target.value, followNumber, setLink))
  }, 250)

  const handleFieldChange = _.debounce((e, field) => {
    const item = {
      ...link
    }

    item[field] = e.target.value

    setLink(item)
    items[followNumber] = item
    dispatch({type: 'SET_ITEMS', payload: [...items]})
  }, 500)


  const renderImage = () => {
    if(link.image_url) {
      return <img src={link.image_url} width='150' height='75'/>
    } else {}
    return 'Link'
  }

  const renderTitle = () => {
    if (link.title === undefined) {
      return (
        <input className="form-control string html_new_post_link_input" type="text" name="html_new_post[link][]"
               onChange={handleChange} defaultValue={link.value}/>
      )
    } else {
      return link.title
    }
  }

  return (
    <>
      <tr>
        <td>
          <i className='fa fa-arrows'/>
        </td>
        <td>
          <label className="col-sm-1 control-label string required" htmlFor="html_new_post_link">
            {renderImage()}
          </label>
        </td>
        <td>
          {renderTitle()}
        </td>
        <td>
          <Popup trigger={<i className="fa fa-pencil"/>} modal>
            {close => (
                <form>
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <div className="d-flex flex-column">
                    <h1 className="self-center">
                      Edit attributes
                    </h1>
                    <PopupInputField name='url' defaultValue={link.url} label='Link url: '
                                     handleChange={(e) => handleFieldChange(e, 'url')}/>
                    <PopupInputField name='title' defaultValue={link.title} label='Title: '
                                     handleChange={(e) => handleFieldChange(e, 'title')}/>
                    <PopupInputField name='post_copy' defaultValue={link.post_copy} label='Post copy: '
                                     handleChange={(e) => handleFieldChange(e, 'post_copy')}/>
                    <PopupInputField name='image' defaultValue={link.image_url} label='Image url: '
                                     handleChange={(e) => handleFieldChange(e, 'image_url')}/>
                    <PopupInputField name='image_alt' defaultValue={link.image_alt} label='Image alt: '
                                     handleChange={(e) => handleFieldChange(e, 'image_alt')}/>
                    <div className="w-100">
                      <div className="self-center form-group">
                        <label htmlFor='description'>Description: </label>
                        <textarea name='description' defaultValue={link.description} className='form-control'
                                  onChange={(e) => handleFieldChange(e, 'description')}/>
                      </div>
                    </div>
                  </div>
                </form>
            )}

          </Popup>
        </td>
        <td className="col-md-1 p-t-10 p-r-15">
          <i className="fa fa-close text-danger pull-right remove-new-link" onClick={removeItem}/>
        </td>
      </tr>
    </>
  )
})

export default LinkInput
