import consumer from '../consumer'
import DailyStatDecorator from '../../javascripts/helpers/daily_stat_decorator'
import 'spin'
import 'urijs'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-daily-stats-index').length > 0) {
    const requestId = Math.random().toString(36).substr(2, 16);
    const tableURL = '';
    const linksReceived = false;
    const clickedLinksRow = {};
    const statTable = $('#network-daily-stat')
    const NETWORK_TABLE_STAT_TEMPLATE = require('../../javascripts/pages/templates/network_table_stat.jst.ejs')
    const NETWORK_LINKS_STAT_TEMPLATE = require('../../javascripts/pages/templates/network_links_stat.jst.ejs')
    const NETWORK_LINK_STAT_ROW_TEMPLATE = require('../../javascripts/pages/templates/network_link_stat_row.jst.ejs')

    statTable.spin('big');

    const renderLinksTableToggleRows = function (links) {
      let link;
      clickedLinksRow.closest('td').spin(false);
      if (links.length === 0) {
        return;
      }

      const decorator = new DailyStatDecorator()
      const linksFormatted = {};
      const attributeNames = Object.keys(links[0].total);

      const linksReceived = true;

      for (link of Array.from(links)) {
        linksFormatted[link.name] = decorator.styleTotalData(link.total, attributeNames);
      }

      for (var attributeName of Array.from(attributeNames)) {
        var row = $(`.${attributeName}`);
        let linksRowsHTML = '';
        _.each(Object.keys(linksFormatted), function (linkHost) {
          const data = {
            link: linkHost,
            total: linksFormatted[linkHost][attributeName],
            class_name: $(row.find('td>a')[0]).data('toggle')
          };
          return linksRowsHTML += NETWORK_LINK_STAT_ROW_TEMPLATE(data);
        });
        row.after(linksRowsHTML);
      }

      $('.columns-toggle').click(linksTableRowCLick);
      return clickedLinksRow.click();
    };

    const receiveLinksData = function (e) {
      if (!linksReceived) {
        const clickedLinksRow = $(event.target);
        $.get({url: tableURL + 'table_stat_type=post&links_stat=true&' + URI().query()});
        return clickedLinksRow.closest('td').spin('small');
      }
    };

    const renderTableData = function (content, stat) {
      let template;
      const decorator = new window.DailyStatDecorator();
      const totalFormatted = decorator.styleTotalData(stat.total, Object.keys(stat.total));

      const statTable = $(`#network-${content.table_name}-stat`);
      statTable.html('');

      switch (content.table_name) {
        case 'video':
          let templateData = {
            total: totalFormatted,
            table_name: 'Videos',
            icon_class: 'fa fa-video-camera'
          };
          template = NETWORK_TABLE_STAT_TEMPLATE(templateData);
          break;
        case 'photo':
          templateData = {
            total: totalFormatted,
            table_name: 'Photos',
            icon_class: 'fa fa-picture-o'
          };
          template = NETWORK_TABLE_STAT_TEMPLATE(templateData);
          break;
        case 'post':
          template = NETWORK_LINKS_STAT_TEMPLATE({total: totalFormatted});
          break;
      }

      statTable.append(template);

      if (content.table_name === 'post') {
        $('.columns-toggle.link-with-caret').click(receiveLinksData);
      }
    };

    const receivedDataForTable = function (data) {
      const {content} = data;
      const {stat} = content;

      if (data.content.request_id === requestId) {
        if (content.links_stat) {
          renderLinksTableToggleRows(stat.links)
        } else {
          renderTableData(content, stat)
        }
      }
    }

    const formTableURl = () => {
      const networkId = $('.network').data('networkId');
      return `/networks/${networkId}/daily_stats/table_stat` + `?request_id=${requestId}&`
    }

    const initializeTable = () => {
      const tableUrl = formTableURl()
      const query = URI().query()
      $.get({url: `${tableURL}table_stat_type=video&${query}`})
      $.get({url: `${tableURL}table_stat_type=photo&${query}`})
      return $.get({url: `${tableURL}table_stat_type=post&${query}`})
    }

    consumer.subscriptions.create('DailyStatTableChannel',
      {
        connected() {
          initializeTable()
        },
        received(data) {
          receivedDataForTable()
        }
      })
  }
})
