import consumer from '../consumer'
import MetricsViewStats from './metrics_view_stats'
import SenderViewStats from './sender_view_stats'
import 'urijs'

const initializeChart = () => {
  const path = window.location.pathname
  $('.deliverability-chart').spin(true)
  $('.container.p-relative').spin(true)
  $.ajax({
    async: false,
    type: 'GET',
    url: `${path}/stats?${URI().query()}`
  })
}

const receivedDataForChart = (data) => {
  $('.deliverability-chart').spin(false)
  $('.container.p-relative').spin(false)
  switch (data.content.view) {
    case 'metrics_view':
      const metricsViewStats = new MetricsViewStats(data)
      metricsViewStats.redrawCharts()
      break
    case 'sender_view':
      const senderViewStats = new SenderViewStats(data)
      senderViewStats.redrawCharts()
      break
    default:
      break
  }
}

consumer.subscriptions.create(
  {channel: 'GooglePostmasterChartsChannel'},
  {
    connected() {
      initializeChart()
    },
    received(data) {
      receivedDataForChart(data)
    }
  }
)
