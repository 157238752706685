import 'ejs'
import moment from 'moment'
import DailyStatDecorator from './daily_stat_decorator'

class DailyStatHelper {
  constructor(data) {
    this.data = data
    this.NETWORK_TABLE_STAT_TEMPLATE = require('../pages/templates/network_table_stat.jst.ejs')
    this.ATTRIBUTES_WITH_PERCENTAGE = ['average_engagement_rate', 'average_ctr', 'percentage_of_fan_count']
    this.dateFinish = ''
    this.dateStart = ''
    this.statTable = $('#network-daily-stat')
    this.chartData = data
    this.mediumChartDateLength = data.length
  }

  initializeChartDates() {
    let chartDateFinish, chartDateStart, query
    query = URI.parseQuery(URI().query())
    if (_.isEmpty(query.chart_date_start)) {
      chartDateStart = moment().subtract(1, 'months').toDate()
    } else {
      chartDateStart = new Date(query.chart_date_start)
    }
    if (_.isEmpty(query.chart_date_finish)) {
      chartDateFinish = new Date()
    } else {
      chartDateFinish = new Date(query.chart_date_finish);
    }
    this.dateStart = moment(chartDateStart)
    this.dateFinish = moment(chartDateFinish)
  }

  calculateTotalSums(total) {
    _.each(this.chartData, function (element) {
      var date;
      date = moment(new Date(element.date));
      if (date.diff(dateStart, 'days') >= 0 && date.diff(dateFinish, 'days') <= 0) {
        return _.each(Object.keys(element.total), function (attributeName) {
          if (attributeName === 'average_engagement_rate') {
            return total[attributeName] += element.total[attributeName] * element.total['total_reach']
          } else {
            return total[attributeName] += element.total[attributeName]
          }
        })
      }
    })
    return total
  }


  formTotalData(total, keys) {
    let formatTotal = {}
    let decorator = new DailyStatDecorator()
    _.each(keys, function (key) {
      var value
      switch (key) {
        case 'average_engagement_rate':
          value = total.total_reach === 0 ? 0 : total.average_engagement_rate / total.total_reach
          formatTotal.average_engagement_rate = ((value * 100).toFixed(2)) + "%"
          break
        case 'average_reach':
          value = total.number_of_posts === 0 ? 0 : total.total_reach / total.number_of_posts
          formatTotal.average_reach = decorator.numberWithDelimiter(value.toFixed(0))
          break
        case 'average_fb_clicks':
          value = total.number_of_posts === 0 ? 0 : total.total_fb_clicks / total.number_of_posts
          formatTotal.average_fb_clicks = decorator.numberWithDelimiter(value.toFixed(0))
          break;
        case 'average_ctr':
          value = total.average_reach === 0 ? 0 : total.average_fb_clicks / total.average_reach
          formatTotal.average_ctr = ((value * 100).toFixed(2)) + "%"
          break
        case 'percentage_of_fan_count':
          value = total.fan_count === 0 ? 0 : total.number_of_posts / total.fan_count
          formatTotal.percentage_of_fan_count = ((value * 100).toFixed(2)) + '%'
          break
        default:
          formatTotal[key] = decorator.numberWithDelimiter(total[key])
      }
    })

    return formatTotal
  }

  fillTableData() {
    let total = {
      average_ctr: 0,
      average_engagement_rate: 0,
      average_fb_clicks: 0,
      average_reach: 0,
      fan_count: 0,
      number_of_posts: 0,
      percentage_of_fan_count: 0,
      total_fb_clicks: 0,
      total_fb_shares: 0,
      total_reach: 0
    }

    total = this.calculateTotalSums(total)
    total = this.formTotalData(total, Object.keys(this.chartData[0].total))

    this.statTable.html('')
    this.statTable.append(this.NETWORK_TABLE_STAT_TEMPLATE({
      total: total,
      table_name: 'Chart',
      icon_class: 'fa fa-line-chart'
    }))
  }

  dataPresenter(attributeName, data) {
    let result = data
    if (this.ATTRIBUTES_WITH_PERCENTAGE.includes(attributeName)) {
      result = data.map(function (current, index, array) {
        for (let key in current.data) {
          current.data[key] = (current.data[key] * 100).toFixed(2)
        }
        return current
      })
    }
    return result
  }

  reformatData(attributeName) {
    let eod, eod_sum, key, medium_eod, medium_eod_number, medium_total, medium_total_number, total, total_sum
    eod = {}
    total = {}
    medium_total = {}
    medium_eod = {}
    eod_sum = 0
    total_sum = 0
    _.each(this.chartData, function (element) {
      eod[element.date] = element.eod[attributeName]
      total[element.date] = element.total[attributeName]
      eod_sum += element.eod[attributeName]
      total_sum += element.total[attributeName]
    });

    medium_eod_number = eod_sum / this.mediumChartDateLength
    medium_total_number = total_sum / this.mediumChartDateLength
    for (key in eod) {
      medium_total[key] = medium_total_number
      medium_eod[key] = medium_eod_number
    }

    return this.dataPresenter(attributeName,
      [
        {
          name: 'EOD',
          data: eod
        }, {
        name: 'Total',
        data: total
      }, {
        name: 'Medium EOD',
        data: medium_eod
      }, {
        name: 'Medium Total',
        data: medium_total
      }
      ]
    )
  }
}


export default DailyStatHelper
