import ReactRailsUJS from 'react_ujs'
import Rails from "@rails/ujs"
import jQuery from 'jquery'
import 'lodash'

window.$ = window.jQuery = jQuery
window._ = _

import 'jquery-slimscroll'
import 'bootstrap-sass'
import 'floatthead/src/jquery.floatThead'
import _ from 'lodash'
import 'jquery-slimscroll/jquery.slimscroll'
import 'underscore/underscore'
import 'moment-timezone'
import 'eonasdan-bootstrap-datetimepicker'
import 'select2'
import 'datatables.net'
import 'datatables.net-scroller'
import 'datatables.net-responsive'
import 'bootstrap-select'
import 'chart.js'
import 'chartkick/chart.js'
import '@nathanvda/cocoon'

import 'tinymce/skins/lightgray/skin.min.css'
import 'tinymce/themes/modern/theme'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'

const componentRequireContext = require.context('components', true)
ReactRailsUJS.useContext(componentRequireContext)

import 'channels'

let webpackContext = require.context('javascripts', true, /\.(?:js|coffee)$/)
for(let key of webpackContext.keys()) { webpackContext(key) }

document.addEventListener('DOMContentLoaded', () => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').content
})

import '../stylesheets/application.sass'
const images = require.context('../images', true)
Rails.start()
