document.addEventListener('DOMContentLoaded', () => {
  if ($('#page-edit').length > 0) {
    const fbUtmTemplatesTable = $('#fb-utm-templates-table')
    let newRecordRow = fbUtmTemplatesTable.find('tr.new-record').clone()

    const dataFrom = row => ({
      id: row.find('.id').html().trim(),

      fb_utm_template: {
        source_page_id: row.find('.source-page').html().trim(),
        destination_page_id: row.find('.destination-page select').val(),
        utm_campaign: row.find('.utm-campaign').html().trim(),
        utm_medium: row.find('.utm-medium').html().trim(),
        utm_source: row.find('.utm-source').html().trim(),
        utm_term: row.find('.utm-term').html().trim()
      }
    })

    const enterEditMode = row => {
      row.find('.destroy-link').addClass('hidden')
      row.find('.save-link').removeClass('hidden')
    }

    const quitEditMode = row => {
      row.find('.save-link').addClass('hidden')
      row.find('.destroy-link').removeClass('hidden')
    }

    fbUtmTemplatesTable.editableTableWidget()

    fbUtmTemplatesTable.on('change', 'tr', event => {
      enterEditMode($(this))
    })

    fbUtmTemplatesTable.on('click', '.save-link', event => {
      const isNewRecord = $(this).parents('.new-record').length > 0
      const savedRow = $(this).parents().eq(1)
      const data = dataFrom(savedRow)

      if (isNewRecord) {
        $.ajax({
          url: "/fb_utm_templates",
          method: 'POST',
          data,
          success(response) {
            if (response.id) {
              savedRow.find('.id').html(response.id)

              savedRow.removeClass('new-record')
              savedRow.addClass('existing-record')

              fbUtmTemplatesTable.find('tbody').append(newRecordRow)
              newRecordRow = newRecordRow.clone()
              fbUtmTemplatesTable.editableTableWidget()

              quitEditMode(savedRow)
            }
          }
        })
      } else {
        $.ajax({
          url: `/fb_utm_templates/${data.id}`,
          method: 'PATCH',
          data,
          success(response) {
            if (response.id) {
              quitEditMode(savedRow)
            }
          }
        })
      }

      event.preventDefault()
    })

    return fbUtmTemplatesTable.on('click', '.destroy-link', event => {
      const deletedRow = $(this).parents().eq(1)
      const data = dataFrom(deletedRow)

      $.ajax({
        url: `/fb_utm_templates/${data.id}`,
        method: 'DELETE',
        success(response) {
          if (response.id) {
            deletedRow.remove()
          }
        }
      })

      event.preventDefault()
    })
  }
})
