module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<h2>\n    <i class=\"fa fa-link\"></i>\n    Links\n</h2>\n<table class=\"table table-striped table-bordered table-hover\">\n   <thead>\n      <tr>\n         <th></th>\n         <th>Total</th>\n      </tr>\n   </thead>\n   <tbody>\n      <tr class='number_of_posts'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"total-posts\" href=\"javascript: void(null);\">\n                Number:\n            </a>\n         </td>\n         <td class=\"total_number_of_posts\">")
    ; __append(escapeFn( total.number_of_posts ))
    ; __append("</td>\n      </tr>\n      <tr class='average_engagement_rate'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"average-engagement-rate\" href=\"javascript: void(null);\">\n              Avg. Engagement:\n            </a>\n         </td>\n         <td class=\"total_average_engagement_rate\">")
    ; __append(escapeFn( total.average_engagement_rate ))
    ; __append("</td>\n      </tr>\n      <tr class='average_reach'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"average-reach\" href=\"javascript: void(null);\">\n              Avg. Reach:\n            </a>\n         </td>\n         <td class=\"total_average_reach\">")
    ; __append(escapeFn( total.average_reach ))
    ; __append("</td>\n      </tr>\n      <tr class='total_reach'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"total-reach\" href=\"javascript: void(null);\">\n              Total Reach:\n            </a>\n         </td>\n         <td class=\"total_total_reach\">")
    ; __append(escapeFn( total.total_reach ))
    ; __append("</td>\n      </tr>\n      <tr class='average_ctr'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"average-ctr\" href=\"javascript: void(null);\">\n              Avg. CTR:\n            </a>\n         </td>\n         <td class=\"total_average_ctr\">")
    ; __append(escapeFn( total.average_ctr ))
    ; __append("</td>\n      </tr>\n      <tr class='average_fb_clicks'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"average-fb-clicks\" href=\"javascript: void(null);\">\n              Avg. FB Clicks:\n            </a>\n         </td>\n         <td class=\"total_average_fb_clicks\">")
    ; __append(escapeFn( total.average_fb_clicks ))
    ; __append("</td>\n      </tr>\n      <tr class='total_fb_clicks'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"total-fb-clicks\" href=\"javascript: void(null);\">\n              Total FB Clicks:\n            </a>\n         </td>\n         <td class=\"total_total_fb_clicks\">")
    ; __append(escapeFn( total.total_fb_clicks ))
    ; __append("</td>\n      </tr>\n      <tr class='percentage_of_fan_count'>\n         <td>\n            <a class=\"columns-toggle link-with-caret\" data-toggle=\"percentage-of-fan-count\" href=\"javascript: void(null);\">\n              % of page size:\n            </a>\n         </td>\n         <td class=\"total_percentage_of_fan_count\">")
    ; __append(escapeFn( total.percentage_of_fan_count ))
    ; __append("</td>\n      </tr>\n   </tbody>\n</table>\n")
  }
  return __output;

}