export const removeSelectedElement = (selectElement, elementValue) => {
  $(selectElement).find('option[value="' + elementValue + '"]').prop("selected", false)
  $(selectElement).trigger('change')
}

export const addBorderToSelectedList = (element) => {
  let selector = '.select2-selection.select2-selection--multiple'
  $(selector).css({border: 0})

  if ($(element).find(':selected').length > 0) {
    $(element).closest('div').find(selector).addClass('bordered-list')
    $(selector).css({border: ''})
  }
  $(element).on('change', function () {
    if ($(element).find(':selected').length === 0) {
      $(element).closest('div').find(selector).removeClass('bordered-list')
    } else {
      $(element).closest('div').find(selector).addClass('bordered-list')
      $(selector).css({border: ''})
    }
  })
}

export const initializeSelect2 = (element, searchable) => {
  $(element).select2({dropdownAutoWidth: true})
  $(element).removeClass('select2-hidden-accessible')
  $(element).addClass('select-col')

  $('.select2.select2-container.select2-container--default').addClass('custom-select2-container col-md-6')
  addBorderToSelectedList(element)

  if (searchable) {
    $(".select2-search__field").attr("placeholder", "Search")

    $(element).on("select2:open change select2:close select2:select select2:unselect", function () {
      $(".select2-search__field").attr("placeholder", "Search")
    })
  } else {
    $('.select2-search.select2-search--inline').css({display: 'none'})
  }

  $(element).on("select2:opening", e => {
    if ($(e.target).hasClass('select2-selection__choice')) {

      let data = $(e.target).data('data')
      removeSelectedElement(element, data.id)
    }

    if (searchable) {
      $('.select2-dropdown.select2-dropdown--below').css({width: $(e.target).closest('span').width()})
    } else {
      e.preventDefault()
    }
  })
}
