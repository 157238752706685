import {removeSelectedPostWithUTMs} from '../../helpers/schedule_post_helper'
import {initializeDateWithoutTimepicker, initializeTimepicker, mergeDateWithTime} from '../../helpers/datetimehelper'
import ScheduledFacebookWallPostsHelper from '../../helpers/scheduled_facebook_wall_posts_helper'
import moment from 'moment'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#scheduled-facebook-wall-posts-index, #network-scheduled-facebook-wall-posts-index').length > 0) {
    const SCHEDULE_ROW_TEMPLATE = require('../templates/schedule.jst.ejs')
    const UTM_TEMPLATE = require('../templates/scheduled_wall_post_edit_utms.jst.ejs')
    const basePath = URI().path()
    let selectedWallPosts = []
    const editModal = $('#edit-wall-posts-modal')
    const postsHelper = new ScheduledFacebookWallPostsHelper()

    const selectedStatus = () => URI().query(true).status

    const showBulkEditModal = () => $.getJSON(basePath, {
      q: {id_in: postsHelper.selectedPostsIds()},
      status: selectedStatus()
    }).then(data => {
      selectedWallPosts = data
      updateScheduleTable()
      _.each(editModal.find('.facebook-post-form'), postElement => {
        const wallPostTime = selectedWallPosts.find(x => x.id === parseInt($(postElement).attr('data-facebook-post-id'))).publish_at
        initializeDateWithoutTimepicker($(postElement).find('.date-without-time-picker')[0], wallPostTime)
        initializeTimepicker($(postElement).find('.timepicker')[0], wallPostTime)
      })
      editModal.find('.remove-selected-post').click(removeSelectedPostWithUTMs)
    })

    const renderScheduleRow = wallPost => SCHEDULE_ROW_TEMPLATE({
      postId: wallPost.id,
      postTitle: wallPost.message,
      publish_at: moment.parseZone(wallPost.publish_at).format('YYYY-MM-DD HH:mm:ss')
    })

    const renderUTMsRow = wallPost => {
      const utmBody = editModal.find('.utms')
      const uri = new URI(wallPost.link)
      const utms = UTM_TEMPLATE({
        wallPost,
        utm: uri.search(true)
      })
      utmBody.append(utms)
    }

    const updateScheduleTable = () => {
      const tableBody = $("#edit-wall-posts-modal table#post-schedule tbody")
      tableBody.html('')
      const utmBody = editModal.find('.utms')
      utmBody.html('')
      const utmTable = editModal.find('#page-utm-table')

      for (let wallPost of Array.from(selectedWallPosts)) {
        tableBody.append(renderScheduleRow(wallPost))
        renderUTMsRow(wallPost)
      }
      utmTable.removeClass('hidden')
    }

    const selectedPostsSchedule = () => {
      const result = {}
      _.each($('#edit-wall-posts-modal table#post-schedule tbody tr'), c => result[$(c).data('facebook-post-id')] = mergeDateWithTime(c))
      return result
    }

    const selectedPostsUTMs = () => {
      const result = {}
      _.each($('#edit-wall-posts-modal table#page-utm-table tbody tr'), c => {
        result[$(c).data('wallPostId')] =
          {
            utm_campaign: $(c).find('.facebook-utm-campaign').val(),
            utm_medium: $(c).find('.facebook-utm-medium').val(),
            utm_source: $(c).find('.facebook-utm-source').val(),
            utm_term: $(c).find('.facebook-utm-term').val()
          }
      })
      return result
    }

    const batchUpdateClick = () => {
      const formData = editModal.find('form').serializeArray()
      const data = {wall_posts: selectedPostsSchedule(), utms: selectedPostsUTMs()}
      $.ajax({
        url: `${basePath}/batch_update`,
        method: 'PATCH',
        data,
        success() {
          for (let checkbox of Array.from($('input[type=checkbox]'))) {
            $(checkbox).prop('checked', false)
          }
          toggleActionButtons()
          editModal.modal('hide')
          location.reload()
        },

        error(response) {
          const forms = editModal.find('.facebook-post-form')
          _.each(forms, function (facebookDateForm) {
            const formElement = $(facebookDateForm)
            formElement.renderFormErrors('scheduled_facebook_wall_post', response.responseJSON[formElement.attr('data-facebook-post-id')], formElement)
          })
        }
      })
    }

    editModal.on('show.bs.modal', showBulkEditModal)
    $('#edit-wall-posts-modal .modal-footer .btn-batch-update').click(batchUpdateClick)
  }
})
