import React from 'react'

const Navigation = () => {
  return (
    <ul className="nav nav-tabs preview-tabs">
      <li className="active" role="presentation">
        <a aria-controls="reader" data-toggle="tab" href="#preview" role="tab">Preview</a>
      </li>
      <li role="presentation">
        <a aria-controls="reader" data-toggle="tab" href="#html" role="tab">HTML</a>
      </li>
      <li role="presentation">
        <a aria-controls="reader" data-toggle="tab" href="#text" role="tab">Text-Only</a>
      </li>
      <li role="presentation">
        <a aria-controls="reader" data-toggle="tab" href="#inbox_preview" role="tab">Inbox Preview</a>
      </li>
    </ul>
  )
}

export default Navigation
