export const removeSelectedPost = (event) => {
  let element = $(event.target)
  let table = element.closest('table')
  if (element.closest('tbody').find('tr').length > 1) {
    element.closest('tr').remove()
    table.trigger('rowRemoved')
  }
}

export const removeSelectedPostWithUTMs = (event) => {
    let postId = $(event.target).closest('tr').data('facebook-post-id')
    let modal = $(event.target).closest('.modal-dialog')
    let utmRow = modal.find('table#page-utm-table tbody tr[data-wall-post-id=' + postId +']')
    utmRow.remove()
    removeSelectedPost(event)
}
