import consumer from '../consumer'
import moment from 'moment'
import DailyStatHelper from '../../javascripts/helpers/daily_stat_helper';

document.addEventListener('DOMContentLoaded', () => {
  if (($('#daily-stats-index').length > 0) || ($('#network-daily-stats-index').length > 0)) {
    const requestId = Math.random().toString(36).substr(2, 16);
    const chartData = [];
    let chartURL = '';
    const activeTab = $($("ul#charts  li.active").find('a').attr('href')).find('div[id^="chart-"]');
    const statTable = $('#network-daily-stat');
    activeTab.spin('small');
    const promises = [];
    const receivedDates = [];

    const formChartURl = () => {
      const networkId = $('.network').data('networkId')
      const pageId = $('.page').data('pageId')
      if (networkId === undefined) {
        chartURL = `/pages/${pageId}/daily_stats/chart_stat`
      } else {
        chartURL = `/networks/${networkId}/daily_stats/chart_stat`
      }
      chartURL = chartURL + `?request_id=${requestId}&`
    }

    const redrawChart = function () {
      const helper = new DailyStatHelper(chartData)
      const chartId = activeTab.attr('id')
      const blockId = activeTab.parent().attr('id')
      Chartkick.charts[chartId].updateData(helper.reformatData(blockId))
      activeTab.spin(false)
      statTable.spin(false)
    }

    const redraw = _.debounce(redrawChart, 1000)

    const tabShow = function (e) {
      const tab = $($(e.target).attr('href')).find('div[id^="chart-"]')
      const helper = new DailyStatHelper(chartData)
      const chartId = tab.attr('id')
      const blockId = tab.parent().attr('id')
      Chartkick.charts[chartId].updateData(helper.reformatData(blockId))
    }

    const createPromises = function (data) {
      const {size} = data;
      let promiseDate = moment(data.start_date);
      let i = 0;
      while (i < size) {
        const promise = new Promise(resolve => receivedDates[promiseDate.format('YYYY-MM-DD')] = resolve)
        promises.push(promise);
        promiseDate = moment(promiseDate).add(1, 'day')
        i++;
      }

      Promise.all(promises).then(redrawChart)
    }

    window.linksTableRowCLick = function (e) {
      const that = $(this)
      const table = that.closest('table')
      const klass = that.data('toggle')
      return table.find(`.${klass}`).toggleClass('hidden')
    };

    $('a[data-toggle="tab"]').on('shown.bs.tab', tabShow)
    $('.columns-toggle').click(linksTableRowCLick)

    const initializeChart = function () {
      formChartURl();
      return $.ajax({
        async: false,
        type: 'GET',
        url: chartURL + URI().query(),
        success: createPromises
      })
    }

    const receivedDataForChart = function (data) {
      if (data.content.request_id === requestId) {
        chartData.push(data.content.stat)
        receivedDates[data.content.stat.date]()
      }
    }

    consumer.subscriptions.create('DailyStatChartChannel', {
      connected() {
        initializeChart()
      },
      received(data) {
        receivedDataForChart(data)
      }
    })
  }
})
