import React, {useState} from 'react'
import SendHtmlModal from './SendHtmlModal'
import ConfirmHtmlModal from './ConfirmHtmlModal'
import {useDispatch} from 'react-redux'
import {
  addToAbCampaign,
  createAbCampaign,
  createCampaign,
  markAsSent,
  publishToLink
} from '../../../redux/actions/EmailHtmlBuilder'

const Footer = () => {
  const dispatch = useDispatch()
  const [added, setAdded] = useState(false)

  const onPublishToLink = () => {
    const html = tinymce.activeEditor.getContent()
    const text_version = document.getElementById('message_text').value
    dispatch(publishToLink(html, text_version))
  }

  const onMarkAsSent = () => {
    if(confirm('Are you sure want to mark these posts as sent?')) {
      dispatch(markAsSent())
    }
  }

  const onAddToAbCampaign = () => {
    setAdded(true)

    dispatch(addToAbCampaign())

    setTimeout(() => {
      setAdded(false)
    }, 1000)
  }

  const handleCreateAbCampaign = () => dispatch(createAbCampaign())
  const handleCreateCampaign = () => dispatch(createCampaign())

  return (
    <div className="modal-footer">
      <button className="btn btn-success btn-mark-send-message" type="button" onClick={onMarkAsSent}>Mark Sent</button>
      <button className="btn btn-success btn-publish-to-link" type="button" onClick={onPublishToLink}>Publish to Link</button>
      <button className="btn btn-success btn-send-message" data-target="#send-html-modal" data-toggle="modal"
              type="button">
        <span className="fa fa-envelope"/> Send
      </button>
      <button className="btn btn-success btn-send-message" type="button" onClick={handleCreateCampaign}>
        <span className="fa fa-envelope"/> Create Campaign
      </button>
      <div className="btn-group dropup btn-ab">
        <button className="btn btn-success" type="button" onClick={onAddToAbCampaign} disabled={added}>
          <span className="fa fa-list-ul"/> {added ? 'Added' : 'Add to A/B campaign'}
        </button>
        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="caret"></span>
          <span className="sr-only">Toggle Dropdown</span>
        </button>
        <ul className="dropdown-menu">
          <li>
              <a href="#"
                 type="button"
                 onClick={handleCreateAbCampaign}>
                Create A/B Campaign
              </a>
          </li>
        </ul>
      </div>
      <SendHtmlModal />
      <ConfirmHtmlModal />
    </div>
  )
}

export default Footer
