document.addEventListener('DOMContentLoaded', () => {
  if ($('#share-partner-emails-show').length > 0) {
    const htmlText = $('#html-message')
    const preview = $('#preview-container')

    const showPreview = () => {
      const html = htmlText.html()
      preview.contents().find('html').html(html)
      preview.contents().find('img').on('load error', () => preview.height(preview.contents().find('html').height()))
    }
    showPreview()
  }
})
