class SelectedPostsHelper {
  constructor() {
    this.selectedPostContainer = $('.container-selected-posts')
    this.selectedPostsIdsMask = "selected_post_ids[]"
    this.appendSelectedPost = this.appendSelectedPost.bind(this)
    this.removeSelectedPost = this.removeSelectedPost.bind(this)
    this.toggleSelectedPost = this.toggleSelectedPost.bind(this)
  }

  removeSelectedPost = (fbpId) => {
    this.selectedPostContainer.find('tr[data-facebook-post-id="' + fbpId + '"]').remove()
    // Temporary comment this piece of code
    _.map($('input[name="' + this.selectedPostsIdsMask + '"]'), input => {
      if (input.value === fbpId) {
        input.remove()
      }
    })
  }

  appendSelectedPost = (fbpId) => {
    let input
    input = $('<input>').attr({
      name: this.selectedPostsIdsMask,
      value: fbpId,
      hidden: true
    }).appendTo('#facebook_post_search')
  }

  changeSelectedPostsInURI = () => {
    const uri = new URI()
    const search = uri.search(true)
    search[this.selectedPostsIdsMask] = _.map($('[name="' + this.selectedPostsIdsMask + '"]'), input => input.value)
    const newUrl = uri.search(search).toString()
    history.replaceState({}, document.title, newUrl)
  }

  toggleSelectedPost = (event) => {
    const checkbox = $(event.currentTarget)
    const checkedRow = checkbox.closest('tr')
    const facebookPostId = checkedRow.attr('data-facebook-post-id')
    if (checkbox.prop('checked')) {
      this.appendSelectedPost(facebookPostId)
      checkedRow.clone().appendTo(this.selectedPostContainer.find('tbody'))
    } else {
      this.removeSelectedPost(facebookPostId)
    }
    this.changeSelectedPostsInURI()
    $('table.table-sticky-header').floatThead('reflow')
  }

  clearSelection = (event) => {
    const uri = new URI()
    const search = uri.search(true)
    search[this.selectedPostsIdsMask] = ''
    const newUrl = uri.search(search).toString()
    history.replaceState({}, document.title, newUrl)
    location.reload()
  }
}

export default SelectedPostsHelper
