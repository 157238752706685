import {useDispatch, useSelector} from 'react-redux'
import {arrayMove} from 'react-sortable-hoc'
import React, {useEffect} from 'react'
import TableList from './TableList'

const Table = () => {
  const dispatch = useDispatch()

  let items = useSelector(state => state.items)
  const onSortEnd = ({oldIndex, newIndex}) => {
    items = arrayMove(items, oldIndex, newIndex)
    items = items.map((item, idx) => {
      return {
        ...item,
        followNumber: idx
      }
    })
    dispatch({type: 'SET_ITEMS', payload: items})
  }

  return <TableList items={items} onSortEnd={onSortEnd} pressDelay={150} />
}

export default Table
