document.addEventListener('DOMContentLoaded', () => {
  if (($('#lifetime-stats-index').length > 0) || ($('#network-lifetime-stats-index').length > 0)) {
    $('.columns-toggle').click(e => {
      const that = $(this)
      const table = that.closest('table')
      const klass = that.data('toggle')
      table.find(`.${klass}`).toggleClass('hidden')
    })
  }
})
