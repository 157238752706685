import React from 'react'
import {Provider} from 'react-redux'
import configureStore from '../../packs/configureStore'
import Form from './Form'

const store = configureStore()

const FormWrapper = ({facebook_posts}) => {
  return (
    <Provider store={store}>
      <Form facebook_posts={facebook_posts} />
    </Provider>
  )
}

export default FormWrapper
