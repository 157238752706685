import BaseViewStats from './base_view_stats'
import IpReputationsHelper from './ip_reputations_helper'

class SenderViewStats extends BaseViewStats {
  constructor(data) {
    super(data)
  }

  formatData() {
    const domainsData = []
    this.domains.forEach(domain => {
      const spamRatio = {
        name: `Spam Ratio`,
        data: {},
        library: {
          yAxisID: 'y1'
        }
      }

      const feedbackLoop = {
        name: `Feedback Loop`,
        data: {},
        library: {
          yAxisID: 'y1'
        }
      }

      const deliveryErrors = {
        name: `Delivery Errors`,
        data: {},
        library: {
          yAxisID: 'y1'
        }
      }

      const domainReputation = {
        name: `Domain Reputation`,
        data: {},
        library: {
          yAxisID: 'y'
        }
      }

      const dmarcSuccessRatio = {
        name: `DMARC Success Ratio`,
        data: {},
        library: {
          yAxisID: 'y'
        }
      }

      const dkimSuccessRatio = {
        name: `DKIM Success Ratio`,
        data: {},
        library: {
          yAxisID: 'y'
        }
      }

      const spfSuccessRatio = {
        name: `SPF Success Ratio`,
        data: {},
        library: {
          yAxisID: 'y'
        }
      }

      const inboundEncryption = {
        name: `Inbound Encryption`,
        data: {},
        library: {
          yAxisID: 'y'
        }
      }

      const outboundEncryption = {
        name: `Outbound Encryption`,
        data: {},
        library: {
          yAxisID: 'y'
        }
      }

      const records = this.selectRecordsForDomain(domain.id)
      records.forEach(el => {
        spamRatio.data[el.date] = el.user_reported_spam_ratio * 100
        feedbackLoop.data[el.date] = el.spammy_feedback_loops * 100
        deliveryErrors.data[el.date] = el.delivery_errors * 100
        domainReputation.data[el.date] = this.reputations[el.domain_reputation] * 100
        dmarcSuccessRatio.data[el.date] = el.dmarc_success_ratio * 100
        dkimSuccessRatio.data[el.date] = el.dkim_success_ratio * 100
        spfSuccessRatio.data[el.date] = el.spf_success_ratio * 100
        inboundEncryption.data[el.date] = el.inbound_encryption_ratio * 100
        outboundEncryption.data[el.date] = el.outbound_encryption_ratio * 100
      })
      domainsData.push({
        name: domain.name,
        id: domain.id,
        data: [spamRatio, feedbackLoop, deliveryErrors, domainReputation, dmarcSuccessRatio, dkimSuccessRatio, spfSuccessRatio, inboundEncryption, outboundEncryption]
      })
    })
    return domainsData
  }

  lineOnClick(evt, chart, domain_id) {
    const array = chart.chart.getElementsAtEventForMode(evt, 'nearest', {intersect: true}, true)
    if (_.isEmpty(array)) return
    let index = array[0].index
    let chartData = chart.getData()

    let time = chartData[0].data[index][0]
    let date = new Date(time)

    let formattedDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
    $.ajax({
      async: true,
      type: 'GET',
      url: `sender_views/ip_reputations?date=${formattedDate}&domain_id=${domain_id}`,
      success: (data) => new IpReputationsHelper(`#accordion-${domain_id}`, data, domain_id, date, true).drawSampleIps()
    })
  }

  redrawIpReputations() {
    this.ip_reputations.forEach(({domain_id, data}) => {
      data = this.formatIpReputationsData(data, 'IP reputations', true)

      let chart = Chartkick.charts[`chart-${domain_id}`]

      chart.updateData(_.concat(chart.getData(), data))
      let options = chart.getOptions()
      let newOptions = {
        library: {
          animation: {
            duration: 1500
          },
          onClick: (evt) => this.lineOnClick(evt, chart, domain_id),
          messages: {
            empty: 'No data available for this domain'
          }
        }
      }
      chart.setOptions(_.merge(options, newOptions))
    })
  }

  redrawCharts() {
    const domainsData = this.formatData()

    domainsData.forEach(domainData => {
      const chart = Chartkick.charts[`chart-${domainData.id}`]
      const options = chart.getOptions()
      const newOptions = {
        library: {
          scales: {
            y1: {
              type: 'linear',
              position: 'right',
              ticks: {
                max: 1,
                min: 0
              }
            }
          }
        }
      }
      chart.setOptions(_.merge(options, newOptions))
      chart.updateData(domainData.data)
    })
    this.redrawIpReputations()
  }
}

export default SenderViewStats
