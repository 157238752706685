document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    const networkId = $('.wrapper-networks').data('networkId')

    const modal = $('#send-push-modal')
    const form = modal.find('.modal-body form')
    const alert = modal.find('.alert')

    const fetchLinkMetaInformation = link => $.getJSON("/link_meta_information", {link})

    const resetForm = () => {
      form.find('.push-link').val('')
      form.find('.push-title').val('')
      form.find('.push-description').val('')
      form.find('.push-send-at').val('')
      alert.hide()
    }

    const showAlert = message => {
      alert.text(message)
      alert.show()
    }

    const prefillFormWithMeta = meta => {
      if (meta) {
        form.find('.push-title').val(meta.title)
        form.find('.push-description').val(meta.description)
        form.find('.push-button1-link').val(meta.url)
      }
    }

    const onLinkChange = e => {
      const link = $(this).val();
      if (!_.isEmpty(link)) {
        fetchLinkMetaInformation(link).then(prefillFormWithMeta)
      }
    }

    const onModalShow = e => {
      resetForm()

      const postId = $('.facebook-post-checkbox:checked').last().closest('tr').data('facebook-post-id')

      if (postId) {
        $.getJSON(`/networks/${networkId}/facebook_posts/${postId}`).then(function (data) {
          form.find('.push-link').val(data.link);
          fetchLinkMetaInformation(data.link).then(prefillFormWithMeta);
        })
      }
    }

    const onButtonSendClick = e => {
      const data = {
        message: {
          link: form.find('.push-link').val(),
          title: form.find('.push-title').val(),
          description: form.find('.push-description').val(),
          segment: form.find('.push-segment').val(),
          send_at: form.find('.push-send-at').val(),
          button1_text: form.find('.push-button1-text').val(),
          button1_link: form.find('.push-button1-link').val(),
          button2_text: form.find('.push-button2-text').val(),
          button2_link: form.find('.push-button2-link').val()
        }
      }

      $.ajax({
        url: '/posts/onesignal.json',
        method: 'POST',
        data,
        complete: response => {
          const json = response.responseJSON

          if (json && !_.isEmpty(json.error)) {
            showAlert(json.error)
          } else {
            modal.modal('hide')
          }
        }
      })
    }

    modal.on('show.bs.modal', onModalShow)
    modal.find('.modal-footer .btn-send-message').click(onButtonSendClick)
    form.find('.push-link').on('blur', onLinkChange)
  }
})
