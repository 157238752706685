import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import {initializeTags} from '../tags_helper'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    let modal = $('#tag-post-modal')
    const networkId = $('.wrapper-networks').data('networkId')
    const tagsElement = modal.find('.facebook-post-tags-input')
    const facebookPostsHelper = new FacebookPostsHelper()
    const selectedPostsContainer = $('.container-selected-posts')
    const clearSelectionBtn = $('.btn-clear-selected-posts')


    toggleSendButtons = () => {
      const selectedRowInf = $('.selected-rows')
      const sendButton = $('.btn-share-partners-send')
      const wpButton = $('.btn-tag-wordpress')
      const tagButton = $('.btn-tag-post')
      // fbButton = $('.btn-send-to-facebook-draft-model')
      const scheduleButton = $('.btn-schedule-facebook-wall-post')
      selectedRowInf.text(
        selectedPostsContainer.find('.facebook-post-checkbox:checked').length + ' rows selected'
      )

      if (selectedPostsContainer.find('.facebook-post-checkbox:checked').length > 0) {
        sendButton.removeClass('disabled')
        tagButton.removeClass('disabled')
        scheduleButton.removeClass('disabled')
        if (wpButton) {
          wpButton.removeClass('disabled')
        }
        selectedRowInf.removeClass('disabled')
        clearSelectionBtn.removeClass('disabled')
      } else {
        sendButton.addClass('disabled')
        tagButton.addClass('disabled')
        scheduleButton.addClass('disabled')
        if (wpButton) {
          wpButton.addClass('disabled')
        }
        selectedRowInf.addClass('disabled')
        clearSelectionBtn.addClass('disabled')
      }
    }
    const onModalShow = e => {
      let facebookPostId
      if (facebookPostId = $('#tag-post-modal').attr('data-facebook-post-id')) {
        $.getJSON(`/tags?facebook_post_id=${facebookPostId}`).then(data => {
          _.each(data, tag => $('input.facebook-post-tags-input').tagsinput('add', tag.name))
          $('input.facebook-post-tags-input').tagsinput('refresh')
        })
      }

      initializeTags('input.facebook-post-tags-input');
    }

    const onModalHide = e => {
      $('#tag-post-modal').removeAttr('data-facebook-post-id')
      $('input.facebook-post-tags-input').tagsinput('removeAll')
      $('input.facebook-post-tags-input').tagsinput('refresh')
      toggleSendButtons()
    }

    const clearLastAddedTag = () => {
      // clear typeahead, that shows last added tag
      $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')
    }

    const refreshClickEvent = () => {
      $('tbody .fa.fa-hashtag').click(e => {
        const facebook_post_id = $(this).closest('tr').attr('data-facebook-post-id')
        $('#tag-post-modal').attr('data-facebook-post-id', facebook_post_id)
        $('#tag-post-modal').modal('show')
      })
      // $('.facebook-post-checkbox').change(toggleSendButtons)
    }

    const updateTags = (tagsValues, facebookPostId) => {
      $.ajax({
        url: `/facebook_post_tags/${facebookPostId}`,
        method: 'PUT',
        data: {
          tags: tagsValues,
          network_id: networkId
        },
        dataType: 'script',
        complete: () => {
          refreshClickEvent()
          modal.modal('hide')
        }
      })
    }

    const addTags = tagsValues => {
      $.ajax({
        url: "/facebook_post_tags",
        method: 'POST',
        data: {
          ids: facebookPostsHelper.selectedPostsIds(),
          tags: tagsValues,
          network_id: networkId
        },
        dataType: 'script',
        complete: () => {
          refreshClickEvent()
          modal.modal('hide')
        }
      })
    }

    const addClickHandler = e => {
      modal = $('#tag-post-modal')
      const tags = modal.find('.facebook-post-tags-input').val()
      const facebookPostId = $('#tag-post-modal').attr('data-facebook-post-id')
      if (facebookPostId) {
        updateTags(tags, facebookPostId)
      } else {
        addTags(tags)
      }
    }

    const addTagThrottled = _.throttle(addClickHandler, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-message').click(addTagThrottled)

    modal.on('show.bs.modal', onModalShow)
    modal.on('hide.bs.modal', onModalHide)
    tagsElement.change(clearLastAddedTag)
  }
})
