import FacebookPostsHelper from '../../helpers/facebook_posts_helper'
import {initializeDateWithoutTimepicker, initializeTimepicker, mergeDateWithTime} from '../../helpers/datetimehelper'
import {clearUtms, onDestinationPageIdChange, utmsForDestinationPages} from '../../helpers/utms'
import {initializeSelect2} from '../../helpers/select2_helper'
import {initializeTags} from '../tags_helper'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#network-facebook-posts-index').length > 0) {
    const SCHEDULE_ROW_TEMPLATE = require('../templates/schedule.jst.ejs')

    let modal = $('.schedule-modal')
    const networkId = $('.wrapper-networks').data('networkId')
    const form = $('#schedule-post')

    const facebookPostsHelper = new FacebookPostsHelper()
    const tagsElement = modal.find('.facebook-post-tags-input')
    let selectedFacebookPosts = []
    let selectedPages = []
    let shortLinks = false
    const destinationPages = modal.find('#scheduled_facebook_wall_post_destination_page_ids')

    const selectedPostsSchedule = modal => {
      const result = {};

      const destinationPageIds = modal.find('#scheduled_facebook_wall_post_destination_page_ids').val();
      _.each(modal.find('table#post-schedule tbody tr'), c => {
        result[$(c).data('facebook-post-id')] = {}
        Array.from(destinationPageIds).map((id) => result[$(c).data('facebook-post-id')][id] = mergeDateWithTime(c))
      })

      return result
    }

    const renderScheduleRow = facebookPost => SCHEDULE_ROW_TEMPLATE({
      postTitle: facebookPost.name,
      postId: facebookPost.id
    })

    const updateScheduleTable = modal => {
      const tableBody = modal.find('table#post-schedule tbody')

      tableBody.html("")

      Array.from(selectedFacebookPosts).map((facebookPost) => tableBody.append(renderScheduleRow(facebookPost)))
    }

    const onModalShow = function (e) {
      modal = $($(e.relatedTarget).data('target'));
      destinationPages.val('');
      clearUtms(modal);

      $.getJSON(`/networks/${networkId}/facebook_posts`, {q: {id_in: facebookPostsHelper.selectedPostsIds()}}).then(data => {
        let el
        selectedFacebookPosts = data
        selectedPages = _.uniqBy(_.map(data, 'page'), 'id')
        updateScheduleTable(modal)
        initializeSelect2(destinationPages, true)
        initializeTags('input.facebook-post-tags-input')
        for (el of Array.from(modal.find('.timepicker'))) {
          initializeTimepicker(el)
        }

        for (el of Array.from(modal.find('.date-without-time-picker'))) {
          initializeDateWithoutTimepicker(el)
        }
      })
    }

    const sendToFacebook = function (e) {
      const hasErrors = validateFrom(form, modal)
      if (!hasErrors) {
        const data = {
          posts: selectedPostsSchedule(modal),
          destination_page_ids: destinationPages.val(),
          tags: tagsElement.val(),
          utms: utmsForDestinationPages(modal, false),
          short_links: shortLinks,
          to_draft: $(this).hasClass('btn-send-to-draft')
        }

        modal.spin('big');
        $.ajax({
          url: `/networks/${networkId}/facebook_posts/schedule_wall_post`,
          method: 'POST',
          data,
          success() {
            modal.spin(false)
            form.clearFormErrors()
            // $(checkbox).prop('checked', false) for checkbox in $('.facebook-post-checkbox')
            toggleSendButtons()
            modal.modal('hide')
          },
          error(response) {
            modal.spin(false)
            const forms = modal.find('.facebook-post-form')
            const json = response.responseJSON

            for (let facebookDateForm of Array.from(forms)) {
              const formElement = $(facebookDateForm)
              const postId = formElement.attr('data-facebook-post-id')
              const failedPostIds = Object.keys(json)

              if (postId && !failedPostIds.includes(postId)) {
                formElement.remove()
              } else {
                formElement.renderFormErrors('scheduled_facebook_wall_post', json[postId], formElement)
              }
            }

            const pageSelector = modal.find('.destination-page-form');
            pageSelector.renderFormErrors('scheduled_facebook_wall_post', response.responseJSON["other"], pageSelector)
          }
        })
      }
    }

    const tagInputTypehead = () => $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')

    $('.wrapper-networks').on('change', '.facebook-post-checkbox', toggleSendButtons)
    tagsElement.change(tagInputTypehead)

    modal.on('show.bs.modal', onModalShow);
    modal.find('.btn-short-links').click(e => shortLinks = !shortLinks)
    destinationPages.change(e => {
      onDestinationPageIdChange(modal, selectedPages, $(this))
    })

    const scheduleThrottled = _.throttle(sendToFacebook, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-to-facebook, .modal-footer .btn-send-to-draft').click(scheduleThrottled)
  }
})
