import {SortableElement} from 'react-sortable-hoc'
import {useDispatch, useSelector} from 'react-redux'
import React from 'react'
import FacebookPost from './FacebookPost'
import LinkInput from './LinkInput'
import HtmlSnippet from './HtmlSnippet'

const TableItem = SortableElement(({type, value, followNumber}) => {
  const dispatch = useDispatch()

  let items = useSelector(state => state.items)

  const removeItem = () => {
    items = items.filter((item, idx) => idx !== followNumber)
    items = items.map((item, idx) => {
      return {
        ...item,
        followNumber: idx
      }
    })

    dispatch({type: 'SET_ITEMS', payload: items})
  }

  const renderItem = () => {
    console.log('renderItem', type)
    switch(type) {
      case 'facebook_post':
        return <FacebookPost key={followNumber} followNumber={followNumber} removeItem={removeItem} />
      case 'Link':
        return <LinkInput key={followNumber} followNumber={followNumber} removeItem={removeItem} />
      case "html_snippet":
        return <HtmlSnippet key={followNumber} followNumber={followNumber} removeItem={removeItem} />
    }
  }

  return (
    <>
      {/*<tr>*/}
      {/*  <td>*/}
      {/*    <i className='fa fa-arrows'/>*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <label className="col-sm-1 control-label string required" htmlFor="html_new_post_link">*/}
      {/*      {type}*/}
      {/*    </label>*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <input className="form-control string html_new_post_link_input" type="text" name="html_new_post[link][]"*/}
      {/*           onChange={handleChange} value={currentValue}/>*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <i className="fa fa-pencil"/>*/}
      {/*  </td>*/}
      {/*  <td className="col-md-1 p-t-10 p-r-15">*/}
      {/*    <i className="fa fa-close text-danger pull-right remove-new-link" onClick={removeItem}/>*/}
      {/*  </td>*/}
      {/*</tr>*/}
      {renderItem()}
    </>
  )
})

export default TableItem
