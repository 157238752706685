import React from 'react'
import PostsForm from './PostsForm'
import TemplatesForm from './TemplatesForm'
import EmailParametersForm from './EmailParametersForm'
import RenderPreview from './RenderPreview'

const Form = ({facebook_posts}) => {
  return (
      <div className='form form-horizontal'>
        <div id='html-modal-accordion'>
          <PostsForm facebook_posts={facebook_posts}/>
          <TemplatesForm/>
          <EmailParametersForm/>
          <RenderPreview />
        </div>
      </div>
  )
}

export default Form
