import React, {useEffect} from 'react'
import PanelHeading from './PanelHeading'
import {useDispatch, useSelector} from 'react-redux'
import {fetchEmailHtmlSenders} from '../../redux/actions/EmailHtmlBuilder'

const PostsForm = () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const {utm_medium, utm_source, utm_term, utm_campaign, items, templates} = state

  const handleChange = (e) => {
    const index = templates.findIndex(elem => elem.id === parseInt(e.target.value))
    const {utm_medium, utm_source, utm_term, utm_campaign} = templates[index]
    const data = {
      template_id: e.target.value,
      sender_name: templates[index].sender.name
    }

    dispatch({type: 'SET_AB_CAMPAIGN_MESSAGES', payload: []})
    dispatch({type: 'SET_ERROR', payload: ''})
    dispatch({type: 'SET_TEMPLATE', payload: data})
    dispatch({type: 'SET_UTMS', payload: {utm_medium, utm_source, utm_term, utm_campaign}})
  }

  useEffect(() => {
    dispatch(fetchEmailHtmlSenders())
  }, [])

  useEffect(() => {
    let newItems = [...items]
    if (items.length === 0) return

    newItems = newItems.map(item => {
      if (item.type !== 'Link' || item.url === undefined) return item

      const uri = new URI(item.url)
      const query = uri.query(true)
      if(utm_medium) query['utm_medium'] = utm_medium
      if(utm_term) query['utm_term'] = utm_term
      if(utm_campaign) query['utm_campaign'] = utm_campaign
      if(utm_source) query['utm_source'] = utm_source

      uri.query(query)

      const newItem = {
        ...item, url: uri.normalize()._string
      }
      return newItem
    })
    dispatch({type: 'SET_ITEMS', payload: newItems})
  }, [utm_medium, utm_source, utm_term, utm_campaign])

  return (
    <div className="panel panel-default">
      <PanelHeading collapseId={'template'}>Template</PanelHeading>
      <div className="panel-collapse collapse in" id="template">
        <div className="panel-body">
          <div className="form-group col-md-9 p-0 form-35-65">
            <label className="control-label p-l-15" htmlFor="share_partner_id">Template:</label>
            <select className='template-selector form-control selectpicker' data-width='65%' onChange={handleChange}>
              {templates.map((sender, idx) => {
                return (<option key={idx} value={sender.id}
                                data-icon={sender.email_template.favorite ? 'glyphicon-star' : null}>
                  {sender.name}
                </option>)
              })}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label" htmlFor="utm_campaign">UTM Campaign:</label>
            <input className="form-control" id="utm_campaign" type="text" defaultValue={utm_campaign}
                   onChange={(e) => dispatch({type: 'SET_UTM_CAMPAIGN', payload: e.target.value})}/>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label" htmlFor="utm_medium">UTM Medium:</label>
            <input className="form-control" id="utm_medium" type="text" defaultValue={utm_medium}
                   onChange={(e) => dispatch({type: 'SET_UTM_MEDIUM', payload: e.target.value})}/>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label" htmlFor="utm_source">UTM Source:</label>
            <input className="form-control" id="utm_source" type="text" defaultValue={utm_source}
                   onChange={(e) => dispatch({type: 'SET_UTM_SOURCE', payload: e.target.value})}/>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label" htmlFor="utm_term">UTM Term:</label>
            <input className="form-control" id="utm_term" type="text" defaultValue={utm_term}
                   onChange={(e) => dispatch({type: 'SET_UTM_TERM', payload: e.target.value})}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostsForm
