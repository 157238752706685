import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {SortableElement} from 'react-sortable-hoc'
import Popup from 'reactjs-popup'
import PopupInputField from '../PopupInputField'

const FacebookPost = SortableElement(({followNumber, removeItem}) => {
  const items = useSelector(state => state.items)
  const [post, setPost] = useState(items[followNumber])
  const dispatch = useDispatch()

  const handleFieldChange = _.debounce((e, field) => {
    const item = {
      ...post
    }

    item[field] = e.target.value

    setPost(item)
    items[followNumber] = item
    dispatch({type: 'SET_ITEMS', payload: [...items]})
  }, 500)

  return (
    <>
      <tr>
        <td>
          <i className='fa fa-arrows'/>
        </td>
        <td>
          <img src={post.image_url} width='150' height='75'/>
        </td>
        <td>
          {post.name}
        </td>
        <td>
          <Popup trigger={<i className="fa fa-pencil"/>} modal>
            {close => (
              <form>
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="d-flex flex-column">
                  <h1 className="self-center">
                    Edit attributes
                  </h1>
                  <PopupInputField name='title' defaultValue={post.title} label='Title: '
                                   handleChange={(e) => handleFieldChange(e, 'title')}/>
                  <PopupInputField name='image' defaultValue={post.image.thumb.url} label='Image url: '
                                   handleChange={(e) => handleFieldChange(e, 'image_url')}/>
                  <PopupInputField name='post_copy' label='Post copy: ' defaultValue={post.post_copy}
                                   handleChange={(e) => handleFieldChange(e, 'post_copy')} />
                  <div className="w-100">
                    <div className="self-center form-group">
                      <label htmlFor='description'>Description: </label>
                      <textarea name='description' defaultValue={post.description} className='form-control'
                                onChange={(e) => handleFieldChange(e, 'description')}/>
                    </div>
                  </div>
                </div>
              </form>
            )}

          </Popup>
        </td>
        <td className="col-md-1 p-t-10 p-r-15">
          <i className="fa fa-close text-danger pull-right remove-selected-post" onClick={removeItem}/>
        </td>
      </tr>
    </>
  )
})

export default FacebookPost
