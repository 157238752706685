import ScheduledFacebookWallPostsHelper from '../../helpers/scheduled_facebook_wall_posts_helper'
import {initializeDateWithoutTimepicker, initializeTimepicker, mergeDateWithTime} from '../../helpers/datetimehelper'
import {removeSelectedPost} from '../../helpers/schedule_post_helper'
import {initializeSelect2} from '../../helpers/select2_helper'
import {initializeTags} from '../tags_helper'
import {onDestinationPageIdChange, utmsForDestinationPages} from '../../helpers/utms'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#scheduled-facebook-wall-posts-index, #network-scheduled-facebook-wall-posts-index').length > 0) {
    const SCHEDULE_ROW_TEMPLATE = require('../templates/schedule.jst.ejs')
    let selectedFacebookPosts = []
    const form = $('#schedule-post')
    const shownPageIds = []
    let shortLinks = false
    const modal = $('#schedule-facebook-wall-post-modal')
    const tagsElement = modal.find('.facebook-post-tags-input')
    const postsHelper = new ScheduledFacebookWallPostsHelper()
    const basePath = URI().path()
    let selectedPages = []

    const renderScheduleRow = facebookPost => SCHEDULE_ROW_TEMPLATE({
      postTitle: facebookPost.name,
      postId: facebookPost.id
    })

    const selectedPostsSchedule = modal => {
      const result = {};
      const destinationPageIds = modal.find('#scheduled_facebook_wall_post_destination_page_ids').val();
      _.each(modal.find('table#post-schedule tbody tr'), c => {
        result[$(c).data('facebook-post-id')] = {};
        Array.from(destinationPageIds).map((id) => result[$(c).data('facebook-post-id')][id] = mergeDateWithTime(c))
      })
      return result
    }

    const updateScheduleTable = modal => {
      const tableBody = modal.find('table#post-schedule tbody')
      tableBody.html("")

      for (let facebookPost of Array.from(selectedFacebookPosts)) {
        tableBody.append(renderScheduleRow(facebookPost))
      }

      modal.find('.remove-selected-post').click(removeSelectedPost)
    }

    const onModalShow = e => {
      const params = {
        q: {
          id_in: postsHelper.selectedPostsIds()
        }
      }

      const uri = new URLSearchParams(location.search)
      const status = uri.get('status')
      if (status) {
        params.status = status
      }

      return $.getJSON(`${basePath}`, params).then(data => {
        let el
        selectedFacebookPosts = data
        selectedPages = _.uniqBy(_.map(data, 'page'), 'id')
        initializeSelect2(modal.find('#scheduled_facebook_wall_post_destination_page_ids'), false)
        updateScheduleTable(modal)
        initializeTags('input.facebook-post-tags-input')
        for (el of Array.from(modal.find('.timepicker'))) {
          initializeTimepicker(el)
        }
        for (el of Array.from(modal.find('.date-without-time-picker'))) {
          initializeDateWithoutTimepicker(el)
        }
      })
    }

    const sendClickHandler = e => {
      const hasErrors = validateFrom(form, modal)

      if (!hasErrors) {
        const data = {
          posts: selectedPostsSchedule(modal),
          destination_page_ids: modal.find('#scheduled_facebook_wall_post_destination_page_ids').val(),
          tags: tagsElement.val(),
          utms: utmsForDestinationPages(modal, true),
          short_links: shortLinks,
          to_draft: $(this).hasClass('btn-send-to-draft')
        }

        modal.spin('big')
        $.ajax({
          url: `${basePath}/schedule_wall_post`,
          method: 'POST',
          data,
          success() {
            modal.spin(false)
            form.clearFormErrors()
            $('.scheduled-facebook-wall-post-checkbox').change(toggleActionButtons)
            for (let checkbox of Array.from($('.scheduled-facebook-wall-post-checkbox'))) {
              $(checkbox).prop('checked', false)
            }
            toggleActionButtons()
            modal.modal('hide')
          },
          error(response) {
            modal.spin(false)
            const forms = modal.find('.facebook-post-form')
            const json = response.responseJSON

            // NOTE: Remove all successfull posts and show form errors
            for (let facebookDateForm of Array.from(forms)) {
              const formElement = $(facebookDateForm)
              const postId = formElement.attr('data-facebook-post-id')
              const failedPostIds = Object.keys(json)
              if (postId && !failedPostIds.includes(postId)) {
                formElement.remove()
              } else {
                formElement.renderFormErrors('scheduled_facebook_wall_post', json[postId], formElement)
              }
            }

            const pageSelector = modal.find('.destination-page-form');
            pageSelector.renderFormErrors('scheduled_facebook_wall_post', json['other'], pageSelector)
          }
        })
      }
    }

    const tagInputTypehead = () => $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '')

    $('#schedule-facebook-wall-post-modal #scheduled_facebook_wall_post_destination_page_ids').change(e => {
      return onDestinationPageIdChange(modal, selectedPages, $(this))
    })

    tagsElement.change(tagInputTypehead)

    modal.on('show.bs.modal', onModalShow)
    modal.find('.btn-short-links').click(e => shortLinks = !shortLinks)

    const scheduleThrottled = _.throttle(sendClickHandler, 2500, {'trailing': false})
    modal.find('.modal-footer .btn-send-to-facebook, .modal-footer .btn-send-to-draft').click(scheduleThrottled)
  }
})
