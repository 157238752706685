document.addEventListener('DOMContentLoaded', () => {
  if ($('#published-emails-show').length > 0) {
    const textarea = $('#html textarea')
    const preview = $('#preview-container')

    const showPreview = () => {
      const html = textarea.val()
      preview.contents().find('html').html(html)
      preview.contents().find('img').on('load error', () => preview.height(preview.contents().find('html').height()))
    }

    showPreview()

    textarea.on('change', showPreview);
  }

  if ($('#published-emails-index').length > 0) {
    const toggleFilters = () => {
      const moreFilters = $('.more-filters')
      if (moreFilters.hasClass('hidden')) {
        moreFilters.removeClass('hidden')
        $(this).text('Less')
      } else {
        moreFilters.addClass('hidden')
        $(this).text('More')
      }
    }

    $('#toogle-filters').click(toggleFilters)
  }
})
