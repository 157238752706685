import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchEspData} from '../../../redux/actions/EmailHtmlBuilder'
import EspListsSelect from './EspListsSelect'
import EspOneSelect from './EspOneSelect'
import {initializeDateWithoutTimepicker, initializeTimepicker} from '../../../javascripts/helpers/datetimehelper'

const SendHtmlModal = () => {
  const dispatch = useDispatch()
  const {template_id} = useSelector(state => state)
  const {admin, segments, campaigns} = useSelector(state => state.esp_data)

  useEffect(() => {
    const timepicker = document.querySelector('.timepicker')
    const dateWithoutTimepicker = document.querySelector('.date-without-time-picker')
    initializeTimepicker(timepicker, null, true)
    initializeDateWithoutTimepicker(dateWithoutTimepicker, null, true)
  }, [])


  useEffect(() => {
    dispatch(fetchEspData())
  }, [template_id])

  const handleSend = () => {
    const time = document.querySelector('.timepicker').value
    const date = document.querySelector('.date-without-time-picker').value

    const selectedOptions = getSelectedOptions(document.querySelector('.select-lists select').options)
    const data = {
      scheduled_time: date + ' ' + time,
      selected_lists: selectedOptions
    }

    dispatch({type: 'SET_SEND_DATA', payload: data})
  }

  const getSelectedOptions = (options) => {
    const selectedOptions = []
    for (let i = 0; i < options.length; i++) {
      if(options[i].selected) selectedOptions.push(parseInt(options[i].value))
    }
    return selectedOptions
  }

  return (
    <>
      <div aria-labelledby="send-html-modal" className="modal fade" data-backdrop="static" id="send-html-modal"
           role="dialog" tabIndex={-1}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="send-share-partners-modal-label">Send HTML</h4>
            </div>
            <div className="modal-body">
              <div className="form-group row m-b-15">
                <label className="control-label top col-md-2 text-right">
                  Schedule
                  <span/>
                </label>
                <div className="col-md-10">
                  <div className="row m-l-2 selected-facebook-post-row">
                    <div className="input-group date m-r-5">
                      <input className="form-control date-without-time-picker" id="publish_at_date"
                             name="scheduled_facebook_wall_post[publish_at]" style={{width: '110px'}} type="text"/>
                      <span className="input-group-addon m-r-20">
                        <i className="fa fa-calendar"/>
                      </span>
                    </div>
                    <div className="input-group date">
                      <input className="timepicker form-control" id="publish_at_time"
                             name="scheduled_facebook_wall_post[publish_at]" style={{width: '110px'}} type="text"/>
                      <span className="input-group-addon">
                        <i className="fa fa-clock-o"/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div id="esp_container">
                <EspListsSelect/>
                <EspOneSelect items={segments} name='segments' labelName='Segment' includeBlank={true} admin={admin}/>
                <EspOneSelect items={campaigns} name='campaigns' labelName='Campaign' includeBlank={true} admin={admin}/>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-white btn-close-confirm" data-dismiss="modal" type="button"> Close</button>
              <button className="btn btn-success btn-send-html-email" data-target="#confirm-html-modal"
                      data-toggle="modal" type="button" onClick={handleSend}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SendHtmlModal
