import Bloodhound from 'typeahead.js'

export const initializeTags = (selector) => {

    const tags = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 10,
        remote: {
            url: '/tags.json?search=%QUERY',
            wildcard: '%QUERY'
        }
    })
    tags.initialize()
    $(selector).tagsinput({
        typeaheadjs: {
            source: tags.ttAdapter(),
            name: 'tags',
            display: 'name',
            minLength: 1,
            limit: 10
        },
        confirmKeys: [13, 9],
        freeInput: true
    })
}

export const toggleAddButton = () => {
    $('.bootstrap-tagsinput').find('input.tt-input').typeahead('val', '');
}

export const refreshClickEvent = (modal_selector, attr_id_name) => {
    $('tbody .fa.fa-hashtag').click(e => {
        $(modal_selector).attr(attr_id_name, $(this).closest('tr').attr(attr_id_name))
        $(modal_selector).modal('show')
    })
}

export const refreshTags = (url, data, method, modal) => {
    $.ajax({
        url: url,
        method: method,
        data: data,
        dataType: 'script',
        complete: () => {
            refreshClickEvent(modal, 'data-scheduled-facebook-wall-post-id')
            modal.modal('hide')
        }
    })
}
